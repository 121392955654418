.dropdown{
    
}

.recordsDropdownCurrentSort{
    display: flex;
    background-color: #E6C068;
    color:black;
    cursor: pointer;
    height:30px;
    align-items: center;
    
    
    font-family: 'Oswald', medium;
    position: relative;
    border-radius: 2px;
}

.recordsDropdownArrow{
    margin-right:0.5vw;
    margin-left:auto;
}

.menuList{
    list-style-type: none;
    background-color: #E8E8E8;
    font-family: 'Oswald', medium;
    z-index: 10;
    height:100vh;
    width:70vw;
    position: absolute;
    top:0;
    
}

@media (max-width:600px){
    .menuContainer{
        height:100vh;
        width:70vw;
        background-color: #212121;
        z-index: 10;
        position: absolute;
        top:0;
    }
    .menuList{
        height:100vh;
        background-color: #212121;
        
    }
    .menuList:first-child{
        margin-top: 40px;
    }
    
}

@media (min-width:601px){
    .menuContainer{
        height:100vh;
        width:70vw;
        background-color: #212121;
        z-index: 10;
        position: absolute;
        top:0;
    }
    .menuList{
        height:100vh;
        background-color: #212121;
        
    }
    .menuList:first-child{
        margin-top: 100px;
    }
}

.menuItem{
    color:#E8E8E8;
    cursor: pointer;
    padding:0.5vh 0 0.5vh 0;
    z-index: 10;
    margin-left:40px;
    margin-bottom: 25px;
    font-family: 'Oswald';
    font-size:22px;
}



.menuItem:hover{
    
   
}

.mobileActive{
    color:#E6C068;
     
 }