.mobileSearchBar{
    display: flex;
    flex-direction: row;
    position: absolute;
    top:0;
    left:0;
    
    
    width:100%;
    height:70px;
    margin-right: 7vw;
    
}

.mobileSearchBarInput{
    width:100%;
    height:100%;
    border:none;
    border-radius: 5px;
    font-size: 10px;
    font-family: 'Noto Sans';
    font-weight: 500;
    font-size: 16px;
    color:#E8E8E8;
    background-color: #2F2F2F;
    padding-left: 15px;
}

.mobileSearchBarLink{
    width:15%;
    height: 100%;
    border-radius: 0 5px 5px 0;
    font-size: 1.2vw;
    background-color: #2F2F2F;
    position: absolute;
    /*top:50%;*/
    right:0;
    /*transform: translateY(-50%);*/
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.mobileSearchBarIcon{
   color:#E6C068;
   font-size: 16px;
   cursor: pointer;
    
}

@media (min-width:1001px) and (max-width:1599px){
    .mobileSearchBar{
        width:400px;
        height:51px;
    }
    .mobileSearchBarInput{
        width:100%;
        height:100%;
        border:none;
        border-radius: 5px;
        font-size: 10px;
        font-family: 'Noto Sans';
        font-weight: 500;
        font-size: 16px;
        color:#121212;
        background-color: #E8E8E8;
        padding-left: 15px;
    }
    .mobileSearchBarInput:focus{
        outline: none;
    }
    
    .mobileSearchBarLink{
       /* width:15%;
        height: 100%;
        border-radius: 0 5px 5px 0;
        font-size: 1.2vw;
        background-color: #2F2F2F;
        position: absolute;
        top:50%;
        right:0;
        transform: translateY(-50%);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
*/
        width:67px;
        height: 100%;
        border-radius: 0 5px 5px 0;
        background-color: #E6C068;
        position: absolute;
        
        right:0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        
    }
    
    .mobileSearchBarIcon{
       color:#E6C068;
       font-size: 16px;
       cursor: pointer;
        
    }
}