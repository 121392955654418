.latestRecordsHeader{
    font-family: 'Oswald';
    font-weight: 700;
    color:#E8E8E8;
    font-size: 45px;
    color:#E6C068;
}

@media (max-width:600px){
    .latestRecordsHeader{
        text-align: center;
        font-size: 25px;
        margin-top: 22px;
        margin-bottom: -30px;
    }
}

@media (min-width:601px) and (max-width:1000px){
    .latestRecordsHeader{
        text-align: center;
        font-size: 32px;
        margin-top: 70px;
        margin-bottom: -30px;
    }
}

@media (min-width:1001px) and (max-width:1599px){
    .latestRecords{
        flex-direction: column;
    }
    .latestRecordsHeader{
        display: flex;
        align-self: center;
        font-family: 'Oswald';
        font-weight: 700;
        font-size: 40px;
        color:#E6C068;
        margin-top: 55px;
        
    }
}

@media (min-width:1600px){
    .latestRecords{
        flex-direction: column;
    }
    .latestRecordsHeader{
        align-self: center;
        font-family: 'Oswald';
        font-weight: 700;
        font-size: 45px;
        color:#E6C068;
        margin-top: 50px;
    }
}
