.record{
    background-color: #f3f3f3;
    display: flex;
    height: 15.5vw;
    width: 15.5vw;
    
}

.recordInfo{
    align-self: flex-end;
    background-color: white;
    width:100%;
    height:25%;
}

.recordInfoArtist{
    font-size: 24px;
    font-weight: bold;
    margin-top:10px;
    margin-left:20px;
}

.recordInfoName{
    font-size: 18px;
    font-weight: bold;
    margin-left:20px;
}
