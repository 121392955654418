.aboutSingle{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.aboutSingleContent{
    width:30vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aboutSingleHeader{
    font-size: 2vw;
    font-weight: bold;
    margin-top: 6vh;
}

.aboutSingleInfo{
    
    font-weight: bold;
    display: flex;
    align-self: flex-start;
    margin-top: 6vh;
    
    
}

.aboutSingleInfo > p{
    margin-top:1vh;
    
}

.aboutSingleInfo > img {
    width:2.5vw;
    height:2.5vw;
    border-radius: 50%;
}

.aboutSingleText{
    margin-top:6vh;
}