.pagination-container {
  display: flex;
  list-style-type: none;
  
  
}
.pagination-container .pagination-item {
  /*padding: 0 12px;*/
  justify-content: center;
  height: 37px;
  width:37px;
  text-align: center;
  margin: auto 2.5px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 50%;
  line-height: 1.43;
  font-size: 12px;
  font-family: 'Oswald';
  font-weight: 500;
  background-color: #E8E8E8;
}
.pagination-item.arrow-left{
  background-color: #E6C068;
}
.pagination-item.arrow-left > img{
  width:100%;
}
.pagination-item.arrow-right{
  background-color: #E6C068;
}
.pagination-item.arrow-right > img{
  width:100%;
}
.pagination-container .pagination-item.dots:hover {
  background-color: #E6C068;
  cursor: default;
}
.pagination-container .pagination-item:hover {
  background-color: #E6C068;
  cursor: pointer;
}
/*.pagination-item.arrow-right:hover{
  content: url('../../assets/icons/nav-arrow-right-black.svg');
  
}
.pagination-item.arrow-left:hover{
  content: url('../../assets/icons/nav-arrow-left-black.svg');
}*/
.pagination-container .pagination-item.selected {
  background-color: #E6C068;
}
.pagination-container .pagination-item .arrow::before {
  position: relative;
 /* top: 3pt;
  Uncomment this to lower the icons as requested in comments*/
  content: '';
 /* By using an em scale, the arrows will size with the font */
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  border-right: 0.23em solid #E6C068;
  border-top: 0.23em solid #E6C068;
  
}
/*.pagination-container .pagination-item .arrow.left {
  content: url('../../assets/icons/nav-arrow-left-gray.svg');
}
.pagination-container .pagination-item .arrow.left:hover {
  content: url('../../assets/icons/nav-arrow-left-gray.svg');
}*/


.pagination-container .pagination-item .arrow.right {
  transform: rotate(45deg);
}
.pagination-container .pagination-item.disabled {
  pointer-events: none;
  background-color: #919191;
  
}
/*.pagination-container .pagination-item.disabled .arrow::before {
  border-right: 0.23em solid #E6C068;
  border-top: 0.23em solid #E6C068;
}*/
.pagination-container .pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}

@media (min-width:601px) and (max-width:1000px){
  .pagination-container .pagination-item{
    width:45px;
    height:45px;
    font-size: 22px;
  }
}

@media (min-width:1001px) and (max-width:1599px){
  .pagination-container .pagination-item{
    width:60px;
    height:60px;
    font-size: 22px;
  }
}

@media (min-width:1600px){
  .pagination-container .pagination-item{
    width:68px;
    height:68px;
    margin:auto 10px;
    font-size: 22px;
  }
}
