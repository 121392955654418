.header{
    display: flex;
    flex-direction: column;
    width:100%;
    height:100vh;
    
    
}

.header > img{
    position: absolute;
    z-index: -1;
    width:100%;
    height:100vh;
}

.headerTop{
    display: flex;
    align-items: center;
    width:100%;
    height: 8vh;
    color:white;
    
}

.headerUserLoggedIn{
    display: flex;
    width:100%;
    flex:1;
    
}

.headerUserLoggedIn > p{
    margin-left: 2vw;
}

.headerTexts{
    display:flex;
    flex-direction: column;
    width:100%;
    height:50vh;
    align-items: flex-start;
    
}


.headerTitle{
    margin-top: 6vh;
    width:30vw;
    font-family: 'Oswald';
    font-size:2vw;
    font-weight: 600;
    color:#E6C068;
    margin-left: 7vw;
    
    
}

.headerText{
    font-size:22px;
    color:#E8E8E8;
    margin-top: 4vh;
    overflow-wrap: break-word;
    width:26%;
    font-family: 'Oswald';
    font-weight: bold;
    line-height: 1.3;
    margin-left: 7vw;
}

.headerAdd{
    display: flex;
    justify-content: flex-end;
    flex:1;
}

.headerNewReviewButton {
    display: flex;
    margin-right: 8vw;
    
    align-items: center;
    justify-content: center;
    width:8vw;
    height:4vh;
    font-size: 14px;
    border:none;
    color:white;
    background-color: black;
    cursor: pointer;
    
}

.headerNewReviewIcon{
    margin-right: 10px;
    font-size: 22px;
    margin-top: 2px;
}



/*
.headerTitles{
    display:flex;
    flex-direction: column;
    align-items: center;
}

.headerTitle{
    position: absolute;
    top:6%;
    font-size:40px;
}

.header > img {
    width:10%;
    margin-top: 80px;
    align-self: center;
    object-fit: cover;
}

*/

@media (min-width:300px){
    .header{
        max-height: 800px;
    }
    .header > img{
        max-height: 800px;
    }
}

@media (min-width:371px){
    .header{
        max-height: 812px;
    }
    .header > img{
        max-height: 812px;
    }
}

@media (min-width:401px){
    .header{
        max-height: 896px;
    }
    .header > img{
        max-height: 896px;
    }
}

@media (min-width:661px){
    .header{
        max-height: 1025px;
    }
    .header > img{
        max-height: 1025px;
    }
}

@media (min-width:981px){
    .header{
        max-height: 800px;
    }
    .header > img{
        max-height: 800px;
    }
}

@media (min-width:1321px){
    .header{
        max-height: 768px;
    }
    .header > img{
        max-height: 768px;
    }
}

@media (min-width:1451px){
    .header{
        max-height: 900px;
    }
    .header > img{
        max-height: 900px;
    }
}

@media (min-width:1701px){
    .header{
        max-height: 1080px;
    }
    .header > img{
        max-height: 1080px;
    }
}

@media (max-width:600px){
    /*.header{
        height:70vh;
    }
    .header > img{
        height:100vh;
    }*/
}

@media (min-width:1001px) and (max-width:1599px){
    .headerAddReview{
        display: flex;
        justify-content: flex-start;
        flex:1;
        margin-left: 135px;
        
    }
    .headerAddLink{
        
        width:140px;
        height:45px;
        text-decoration: none;
    }

    .headerNewReviewButton {
        display: flex;
        margin-right: 8vw;
        align-items: center;
        justify-content: center;
        width:140px;
        height:45px;
        font-size: 14px;
        border:none;
        color:white;
        background-color: #E6C068;
        border-radius: 5px;
        cursor: pointer;
        text-decoration: none;
        
    }
    .headerNewReviewButton > p{
        font-family: 'Oswald';
        font-weight: 500;
        font-size: 17px;
        color:#121212;
    }
    .headerNewReviewIcon{
        margin-right: 10px;
        font-size: 22px;
        margin-top: 2px;
        width:26px;
    }
}

@media (min-width: 1600px){
    .header{
        max-width: 1920px;
        max-height: 1080px;
    }
    .header > img{
        max-width: 1920px;
        max-height: 1080px;
    }
    .headerTexts{}
    .headerTitle{
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 45px;
        width:610px;

    }
    .headerText{
        font-family: 'Noto Sans';
        font-weight: 500;
        font-size: 22px;
        width:600px;
    }
}