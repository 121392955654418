* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

body {
    min-height: 100vh;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    background-color: #212121;
  }

#root{
  width:100%;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.App {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }