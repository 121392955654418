.profile{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #121212;
    align-items: center;
}
.profileWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:1640px;
    height:1200px;
    background-color: #212121;
    border-radius: 20px;
    margin-top: 40px;
}
.profileHeader{
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    width:906px;
    align-items: center;
    justify-content: space-between;
    font-family: 'Oswald';
    margin-top: 45px;
    
}
.profileHeaderText{
    font-weight: 600;
    font-size: 45px;
    
    text-align: center;
    color: #E6C068;
    
    
}
.profileLogoutButton{
    font-family: 'Oswald';
    font-weight: 600;
    font-size: 20px;
    width:210px;
    height:60px;
    margin-right: 80px;
    background-color: #E6C068;
    border:none;
    border-radius: 5px;
    cursor: pointer;
}
.profilePasswordSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:600px;
    height:750px;
    background-color: #121212;
    margin-top: 75px;
    border-radius: 20px;
}
.profilePasswordSectionHeader{
    font-family: 'Oswald';
    font-weight: 600;
    font-size: 35px;
    color:#E6C068;
    margin-top: 40px;
}
.profilePasswordSentionRules{
    font-family: 'Noto Sans';
    font-weight: 500;
    font-size: 20px;
    color: #E8E8E8;
    margin-top: 40px;
    padding:10px;
}
.profilePasswordSentionRules > ul{
    margin-left: 20px;
}
.profilePasswordSentionRules > ul > li::marker{
    color:#E6C068;
    font-size: 1.5rem;
    
}
.profilePasswordForm{
    width:430px;
    margin-top: 40px;
    
}
.profileInputFieldDesc{
    font-family: 'Oswald';
    font-weight: 600;
    font-size: 22px;
    color:#E6C068;
}
.profilePasswordForm > input{
    width:100%;
    height:55px;
    margin-top: 15px;
    border-radius: 5px;
    padding-left: 10px;
    border:none;
    font-family: 'Noto Sans';
    font-weight: 500;
    font-size: 18px;
}
.profilePasswordForm > button{
    width:100%;
    height:55px;
    border:none;
    margin-top: 80px;
    background-color: #E6C068;
    color:#121212;
    font-family: 'Oswald';
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;
}

@media (min-width:1001px) and (max-width:1599px){
    .profileWrapper{
        width:1010px;
        height:880px;
    }
    .profilePasswordSection{
        width:450px;
        height:630px;
        margin-top: 50px;
    }
    .profileHeaderText{
        margin-left: 315px;
        
    }
    .profilePasswordForm{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }
    .profileInputFieldDesc{
        margin-left:45px;
    }
    .profilePasswordForm > input{
        width:340px;
        height:44px;
        align-self: center;
        
    }
    .profilePasswordForm > button{
        width:340px;
        height:44px;
        align-self: center;
        margin-top: 30px;
    }
}