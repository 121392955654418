.aboutSection{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aboutSectionPreview{
    display: flex;
    background-color: #121212;
    color:#E8E8E8;
    width:85vw;
    height:500px;
    margin-top: 10vh;
    border-radius: 2vw;
}

.aboutSectionPreviewLeft{
    display: flex;
    flex-direction: column;
    flex:1;
    font-size: 1vw; 
    
}

.aboutSectionPreviewLeft > p{
    margin-left: 2vw;
    margin-top: 4vh;
    font-family: 'Oswald';
    font-size:2vw;
    font-weight: bold;
    color:#E8E8E8;
    
}

.aboutSectionPreviewText{
    margin-left: 2vw;
    margin-top: 4%;
    font-family: 'Noto Sans';
    font-weight: 500; /* font weight not working? */
    color:#E8E8E8;
    font-size: 22px;
}

.aboutSectionLink{
    width:10vw;
    height:60px;
    display: flex;
    
    margin-left: 2vw;
    margin-top: 4vh;
    background-color: #E6C068;
    color:#121212;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    font-family: 'Oswald';
    font-size: 20px;
    font-weight: 600;
    border-radius: 10px;
}

.aboutSectionPreviewRight{
    display: flex;
    flex:1;
    align-items: center;
    justify-content: space-evenly;
    
}

.aboutSectionPreviewProfile{
    display: flex;
    flex-direction: column;
    align-items: center;
    
    
    
}

.aboutSectionPreviewPic{
    width:12vw;
    height:12vw;
    border-radius: 50%;
    
    margin-top: -15%;
    object-fit: cover;
    border:5px solid #E6C068;

}

.aboutSectionPreviewName{
    font-family: 'Oswald';
    font-size: 25px;
    font-weight: bold;
    margin-top:3%;
}

@media (max-width:600px){
    .mobileAboutSectionPreview{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        overflow-wrap: break-word;
        width:310px;
        height:auto;
        text-align: left;
        overflow: hidden;
        -webkit-line-clamp:12;
        -webkit-box-orient: vertical;
        color:#E8E8E8;
        font-family: 'Noto Sans';
        font-size: 16px;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
    }
    .mobileAboutSectionPreview > p{
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 25px;
        text-align: center;
        margin-top: 20px;
        color:#E6C068;

    }
    .mobileAboutSectionPreviewText{
        margin-top: 20px;
        text-align: center;
        font-family: 'Noto Sans';
        font-size: 15px;
        line-height: 22px;
    }
    .mobileAboutSectionLink{
        width:143px;
        height:43px;
        display: flex;
        margin-top: 20px;
        background-color: #E6C068;
        color:#121212;
        text-decoration: none;
        justify-content: center;
        align-items: center;
        font-family: 'Oswald';
        font-size: 13px;
        font-weight: 600;
        border-radius: 10px;
        align-self: center;
    }
    .mobileAboutSectionProfiles{
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        width:306px;
    }
    .mobileAboutSectionProfile{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width:148px;
        height:200px;
        background-color: #121212;
        border-radius: 10px;
        
    }
    .mobileAboutSectionPreviewPic{
        width:93px;
        height:93px;
        border-radius: 50%;
        
        
        object-fit: cover;
        border:3px solid #E6C068;
    }
    .mobileAboutSectionPreviewName{
        font-family: 'Oswald';
        font-weight: 700;
        font-size: 14px;
        color:#E8E8E8;
        margin-top: 16px;
    }
    .mobileAboutSectionPreviewEmail{
        font-family: 'Oswald';
        font-weight: 300;
        font-size: 10px;
        margin-top: 5px;
    }
}

@media (min-width:601px){
    .mobileAboutSectionPreview{
        margin-top: 20px;
        overflow-wrap: break-word;
        width:650px;
        height:auto;
        text-align: left;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        -webkit-line-clamp:12;
        -webkit-box-orient: vertical;
        color:#E8E8E8;
        font-family: 'Noto Sans';
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }
    .mobileAboutSectionPreview > p{
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 32px;
        text-align: center;
        margin-top: 20px;
        color:#E6C068;
    }
    .mobileAboutSectionPreviewText{
        width:530px;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    .mobileAboutSectionLink{
        width:170px;
        height:40px;
        display: flex;
        margin-top: 40px;
        
        background-color: #E6C068;
        color:#121212;
        text-decoration: none;
        justify-content: center;
        align-items: center;
        font-family: 'Oswald';
        font-size: 16px;
        font-weight: 600;
        border-radius: 10px;
    }
    .mobileAboutSectionProfiles{
        display: flex;
        justify-content: space-between;
        margin-top: 76px;
        width:650px;
    }
    .mobileAboutSectionProfile{
        display: flex;
        flex-direction: column;
        align-items: center;
        
        width:315px;
        height:430px;
        background-color: #2F2F2F;
        border-radius: 10px;
        
    }
    .mobileAboutSectionPreviewPic{
        width:200px;
        height:200px;
        border-radius: 50%;
        margin-top:45px;
        
        object-fit: cover;
        border:5px solid #E6C068;
    }
    .mobileAboutSectionPreviewName{
        margin-top:35px;
        font-family: 'Oswald';
        font-weight: 700;
        font-size: 29px;
    }
    .mobileAboutSectionPreviewEmail{
        margin-top: 14px;
        font-family: 'Oswald';
        font-weight: 300;
        font-size: 18px;
    }
}

@media (min-width:1001px) and (max-width:1599px) {
    .aboutSection{
        height:600px;
    }
    .aboutSectionPreview{
        display: flex;
        justify-content: space-between;
        width:1010px;
        height:680px;
        margin-top: 166px;
        background-color: #212121;
    }
    .aboutSectionPreviewLeft{
        width:440px;
        
    }
    .aboutSectionPreviewRight{
        width:495px;
        flex:none;
        align-items: flex-start;
        justify-content: space-between;
    }
    .aboutSectionPreviewProfile{
        background-color: #121212;
        width:236px;
        height:322px;
        border-radius: 10px;
        margin-top:15px;
    }
    .aboutSectionPreviewPic{
        width:150px;
        height:150px;
        margin-top: 34px;
    }
    .aboutSectionPreviewName{
        font-family: 'Oswald';
        font-weight: 700;
        font-size: 20px;
        margin-top:34px;
    }
    .aboutSectionPreviewEmail{
        font-family: 'Oswald';
        font-weight: 300;
        font-size: 18px;
        margin-top:5px;
    }
    .aboutSectionPreviewLeft > p{
        margin-top:0;
        margin-left: 0;
        color:#E6C068;
        font-size: 40px;
    }
    .aboutSectionPreviewText{
        width:inherit;
        height:200px;
        margin-left: 0;
        margin-top: 23px;
        line-height: 26px;
        font-size: 18px;
    }
    .aboutSectionLink{
        width:210px;
        height:60px;
        margin-left: 0;
        margin-top:20px;
        border-radius: 5px;
    }
}

@media (min-width:1600px){
    .aboutSectionPreview{
        width:1640px;
        height:600px;
        margin-top: 166px;
        background-color: #212121;
    }
    .aboutSectionPreviewProfile{
        background-color: #2F2F2F;
        width:315px;
        height:430px;
        border-radius: 10px;
        background-color: #121212;
    }
    .aboutSectionPreviewPic{
        width:200px;
        height:200px;
        margin-top: 45px;
    }
    .aboutSectionPreviewName{
        font-family: 'Oswald';
        font-weight: 700;
        font-size: 29px;
        margin-top:34px;
    }
    .aboutSectionPreviewEmail{
        font-family: 'Oswald';
        font-weight: 300;
        font-size: 22px;
        margin-top:10px;
    }
    .aboutSectionPreviewLeft > p{
        margin-top:70px;
        margin-left: 140px;
        color:#E6C068;
    }
    .aboutSectionPreviewText{
        width:607px;
        height:230px;
        margin-left: 140px;
        margin-top: 26px;
        font-family: 'Noto Sans';
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;

    }
    .aboutSectionLink{
        margin-left: 140px;
        margin-top: 26px;
        border-radius: 5px;
    }
}