@media (max-width:600px){
    .App{
        width:100vw;
        max-width: 600px;
    }
}

@media (min-width:601px){

}

@media (min-width:1600px){

}