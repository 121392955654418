.records{
    width:100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;

}

.recordsTopInfoTop{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.recordsTopInfo{
    display: flex;
    flex-direction: row;
    
    align-items: center;
    justify-content: space-between;
    
    
    width:85vw;
    height:4vh;
    
    margin-top:5vh;
}

.recordsGenre{
    display: flex;
    color:#E8E8E8;
    font-family: 'Oswald';
    font-weight: 700;
    font-size: 1.5vw;
}

.recordsDropdown{
    
    
    display: flex;
    justify-items: center;
    align-items: center;
    z-index: 10;
    align-items: center;
    
}

.recordsSortingInfo{
    color:#E8E8E8;
    font-size:0.833vw;
    height:30px;
    font-family: 'Oswald', medium;
}


.recordSection{
    display: flex;
    width:85vw;
    
    
    flex-wrap: wrap;

    
}

.record{
    margin-top:2vw;
}

.recordSectionCard{
    margin-left: 1.87vw;
    margin-top:4vh;
}

/*.recordSectionCard:first-child{
    margin-left: 0;
}*/
.recordSectionCard:nth-child(5n+1){
    margin-left: 0;
}


.recordsPagination{
    
    display: flex;
    justify-content: flex-end;
    width:1600px;
    align-self: center;
    color:#E8E8E8;
    
    
}

@media (max-width:600px){
    .recordSection{
        width:310px;
    }
    .recordsTopInfo{
        display: flex;
        flex-direction: row;
        width:310px;
        height:50px;
        align-items: flex-start;
        margin-top: 0;
        
    }
    .otherGenreText{
        width:310px;
        font-family: 'Oswald';
        font-weight: 300;
        font-size: 15px;
        color:#E8E8E8;
        align-self: center;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
    }
    .recordsTopInfoLeft{
        display: flex;
        flex-direction: column;
        flex:1;
        margin-top: 13px;

    }
    .recordsHamburgerMenu{
        margin-top: 10px;
    }
    .recordsTopInfoRight{
        display: flex;
        flex:1;
        margin-top: 27px;
        align-items: flex-end;
        justify-content: flex-end;
        color:#E8E8E8;
        font-family: 'Noto Sans';

    }
    .recordSectionCard{
        margin-left: 0;
        margin-top:27px;
    }
    .recordSectionCard:nth-child(2n){
        margin-left: 10px;
    }
    .recordsGenre{
        font-size:25px;
        color:#E6C068;
    }
    .recordsTotal{
        color:#E8E8E8;
        align-self: center;
        font-family: 'Oswald';
        font-weight: 300;
        font-size: 12px;
    }
    .mobileSortTrigger{
        display: flex;
        width:83px;
        height:32px;
        border-radius: 5px;
        background-color: #E6C068;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .mobileSortIcon{
        transform: scaleY(-1);
        width:20px;
    }
    .mobileSortTrigger > p{
        font-size: 14px;
        font-family: 'Oswald';
        margin-left:4px;
        height:20px;
        margin-bottom: 3px;
    }
    .recordsPagination{
        width:310px;
        margin-top: 25px;
        justify-content: center;
        margin-bottom: 0;
    }
}

@media (min-width:601px){
    .recordSection{
        
        width:650px;
        justify-content: space-between;
    }
    .recordsTopInfo{
        display: flex;
        flex-direction: row;
        width:650px;
        height:50px;
        align-items: flex-start;
        margin-top: 0;
        
    }
    .otherGenreText{
        width:400px;
        font-family: 'Oswald';
        font-weight: 300;
        font-size: 20px;
        color:#E8E8E8;
        align-self: center;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
    }
    .recordsTopInfoLeft{
        display: flex;
        flex-direction: column;
        flex:1;

    }
    .recordsHamburgerMenu{
        margin-top: 10px;
    }
    .recordsTopInfoRight{
        display: flex;
        flex:1;
        height:44px;
        align-items: center;
        justify-content: flex-end;
        color:#E8E8E8;
        font-family: 'Noto Sans';

    }
    .recordSectionCard{
        margin-left: 0;
        margin-top:4vh;
    }
    .recordSectionCard:nth-child(2n){
        margin-left: 10px;
    }
    .recordsGenre{
        font-size:32px;
        color:#E6C068;
    }
    .recordsTotal{
        color:#E8E8E8;
        align-self: center;
        font-family: 'Oswald';
        font-weight: 300;
        font-size: 18px;
    }
    .mobileSortTrigger{
        display: flex;
        width:107px;
        height:46px;
        border-radius: 5px;
        background-color: #E6C068;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .mobileSortIcon{
        transform: scaleY(-1);
        width:20px;
    }
    .mobileSortTrigger > p{
        font-weight: 500;
        font-size: 18px;
        font-family: 'Oswald';
        margin-left:8px;
    }
    .recordsPagination{
        width:310px;
        margin-top: 25px;
        justify-content: center;
        margin-bottom: 0;
    }
}

@media (min-width:1001px) and (max-width:1599px){ 
    .recordsTopInfo{
        flex-direction: column;
        width:1010px;
        height:200px;
    }
    .recordsGenre{
        font-family: 'Oswald';
        font-weight: 700;
        font-size: 35px;
        margin-top: 30px;
        align-self: center;
    }
    .otherGenreText{
        width: auto;
        font-family: 'Oswald';
        font-weight: 300;
        font-size: 20px;
        color:#E8E8E8;
        align-self: center;
        margin-bottom: 50px;
    }
    .recordsDropdown{
        margin-top:20px;
        width:245px;
        cursor: pointer;
    }
    .recordsDropdownSortTrigger{
        display: flex;
        width:245px;
        height:45px;
        background-color: #E6C068;
        border-radius: 5px;
        justify-content: space-evenly;
        align-items: center;
        position: relative;
        z-index: 15;
        
    }
    .recordsDropdownSortStateDisplay{
        font-family: 'Oswald';
        font-weight: 500;
        font-size: 22px;
        color:#121212;
        text-align: center;

    }
    .dropdownSortIcon{
        transform: scaleY(-1);
        width:25px;
    }
    .recordsDropdownSortArrow{
        width:30px;
        margin-top: 5px;
    }
    .recordsTotal{
        font-family: 'Oswald';
        font-weight: 300;
        margin-top: -40px;
        font-size: 18px;
        color:#E8E8E8;
        align-self: center;

    }
    .recordSection{
        width:1010px;
        margin-top: 25px;
    }
    .recordSectionCard:nth-child(5n+1){
        margin-left: 0;
    }
    .recordSectionCard{
        margin-left: 0;
    }
    .recordSectionCard:nth-child(2n){
        margin-left: 0;
    }
    .recordsPagination{
        margin-top: 50px;
        margin-bottom: 40px;
    }
}

@media (min-width:1600px){
    .recordsTopInfo{
        flex-direction: column;
        width:1640px;
        height:200px;
    }
    .recordsGenre{
        font-family: 'Oswald';
        font-weight: 700;
        font-size: 35px;
        margin-top: 30px;
        align-self: center;
    }
    .otherGenreText{
        width:auto;
        font-family: 'Oswald';
        font-weight: 300;
        font-size: 22px;
        color:#E8E8E8;
        align-self: center;
        margin-top: -40px;
        margin-bottom: 60px;
    }
    
    .recordsDropdown{
        margin-top:-35px;
        width:245px;
        cursor: pointer;
    }
    .recordsDropdownSortTrigger{
        display: flex;
        width:245px;
        height:45px;
        background-color: #E6C068;
        border-radius: 5px;
        justify-content: space-evenly;
        align-items: center;
        position: relative;
        z-index: 15;
        
    }
    .recordsDropdownSortStateDisplay{
        font-family: 'Oswald';
        font-weight: 500;
        font-size: 22px;
        color:#121212;
        text-align: center;

    }
    .dropdownSortIcon{
        transform: scaleY(-1);
        width:25px;
    }
    .recordsDropdownSortArrow{
        width:30px;
        margin-top: 5px;
    }
    .recordsTotal{
        font-family: 'Oswald';
        font-weight: 300;
        margin-top: -90px;
        font-size: 18px;
        color:#E8E8E8;

    }
    .recordSection{
        width:1640px;
        margin-top: 25px;
    }
    .recordSectionCard:nth-child(5n+1){
        margin-left: 0;
    }
    .recordSectionCard{
        margin-left: 0;
    }
    .recordSectionCard:nth-child(2n){
        margin-left: 0;
    }
    .recordsPagination{
        margin-top: 100px;
        margin-bottom: 0;
    }
}


