.topbar {
    display:flex;
    flex-direction: column;
    width:100%;
    height: 300px;
    align-items: center;
}

.blockazzLogo{
    position: absolute;
    width:10%;
    top:5%;
    left:46%;
    align-self: center;
}

.topbarTop{
    display: flex;
    width:100%;
    height:150px;
    background-color: rgb(243, 243, 243);
    flex:1;
    
}

.topbarLeft{
    flex:1;
    
}

.topbarRight{
    display: flex;
    flex:1;
    align-items: center;
    justify-content: center;
}

.topbarBottom{
    display: flex;
    width:100%;
    height:150px;
    justify-content: center;
    align-items: center;
    flex:1;
}

.topbarBottomLeft{
    display: flex;
    flex:1;
}

.topbarBottomRight{
    display: flex;
    justify-content: center;
    flex:1;
    
}

.newReviewButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width:150px;
    height:50px;
    font-size: 14px;
    border:none;
    color:white;
    background-color: black;
    cursor: pointer;
}

.newReviewIcon{
    margin-right: 10px;
    font-size: 22px;
    margin-top: 2px;
}




.topbarList{
    display:flex;
    list-style: none;
    margin-top:120px;
}

.topbarListItem{
    font-size:30px;
    margin-left:20px;
    cursor: pointer;
    color: rgb(160, 160, 160);
}

.topbarListItem:hover{
    color:rgb(78, 78, 78);
}

.topbarBurgerMenu{
    margin-top:15px;
    margin-left:20px;
    cursor: pointer;
    font-size: 20px;
}




