.add{
    display: flex;
    flex-direction: column;
    width:100%;
    background-color: #121212;
}

.add > h1{
    margin-top:6%;
    align-self: center;
    color:#E8E8E8;
}

.addForm{
    display: flex;
    flex-direction: column;
    margin-top:25px;
    width:1640px;
    
    align-self: center;
    background-color: #212121;
    border-radius: 20px;
    
}

.addFormTop{
    display: flex;
    flex-direction: column;
    width:540px;
    height:650px;
    align-self: center;
    font-family: 'Oswald';
    font-weight: 600;
    font-size: 22px;
    color:#E6C068;
    
}
.addFormHeader{
    font-size: 45px;
    text-align: center;
    margin-top: 50px;
}
.addFormFieldDesc{
    margin-top:35px;
}
.addFormDropdown{
    display: flex;
    justify-items: center;
    align-items: center;
    z-index: 10;
    align-items: center;
    margin-top: 14px;
}
.addFormDropdownTrigger{
    display: flex;
    width:180px;
    height:55px;
    background-color: #E8E8E8;
    border-radius: 10px;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    z-index: 15;
    
}



.addFormLeft{
    display: flex;
    flex-direction: column;
    width:30vw;
    height:25vw;
    background-color: #212121;
    color:#E8E8E8;
    border-radius: 2vw;
    margin-left:3vw;

}

.addFormTopHorizontal{
    display: flex;
    flex-direction: row;
    margin-top:15px;
    justify-content: space-between;
    
}

.addFormLeftVertical{
    
}

.addFormLeftVertical:first-child{
    margin-left: 0;
}



.addFormLeft > p{
    font-size:0.7vw;
    
    margin-top: 3vh;
}

.addFormLeft > p:first-child{
    font-size:1.5vw;
    margin-top: 2vh;
}

.addFormLeft > input, select{
    padding:5px;
    border-radius: 5px;
}

.addFormRight{
    display: flex;
    flex-direction: column;
    margin-left: 4vw;
    width:25vw;
    height:25vw;

    align-items: center;
    justify-content: center;
    
}

.largeInput{
    width:100%;
    height:55px;
    border: 1px solid black;
    border-radius: 10px;
    
    margin-top:5px;
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 18px;
    padding-left: 10px;
    
}

.mediumInput{
    width:6vw;
    height:3vh;
    border: 1px solid black;
    font-size:0.7vw;
    margin-top:1vh;
}

.smallInput{
    width:140px;
    height:55px;
    border: 1px solid black;
    border-radius: 10px;
    font-family: 'Oswald';
    font-weight: 500;
    font-size:18px;
    margin-top:14px;
    text-align: center;

}

.addImage{
    display:flex;
    width:100%;
    height:100%;
    align-self: flex-end;
    border:2px solid black;
}

.addImageIcon{
    font-size:42px;
    cursor: pointer;
}

.addIconText{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width:100%;
    height:100%;
    
}

.addCloseImageIcon{
    position:absolute;
    top:0;
    right:2px;
    z-index: 1;
    font-size: 32px;
    cursor: pointer;
    
}

.addImagePreview{
    position: relative;
    width:100%;
    height:100%;
}

.previewImage{
    width:100%;
    height:100%;
    object-fit: cover;
    z-index: 2;
}

.addFormBottom{
    display: flex;
    align-self: center;
    /*column-gap: 10vw;*/
    width:1480px;
    
    
    margin-top:5vh;
}

.addFormEditor{
    width:850px;
    
    
}

.addFormImage{
    background-color: #E8E8E8;
    width:500px;
    height:500px;
    margin-left: 130px;
}


.addFormReviewInput{
    -webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
    width:100%;
    height:80%;
    resize: none;
    background-color: red;
    
}

.RichEditor-root{
    height:1170px;
    border-radius: 2%;
    width: inherit;
    overflow-x: hidden;
    overflow-y: auto;
    
}

.RichEditor-editor{
    width:40vw;
    
    border:1px solid black;
}

/*.DraftEditor-editorContainer{
    width:42.8vw;
    height:600px;
    border:1px solid black;
    
}*/



.addReviewButton{
    float:right;
    width:210px;
    height:60px;
    border:none;
    background-color: gray;
    background-color: #E6C068;
    margin-top:1%;
    cursor: pointer;
    align-self: flex-end;
    margin-right: 80px;
    margin-bottom: 3vh;
    border-radius: 5px;
    color:#121212;
    font-family: 'Oswald';
    font-weight: 600;
    font-size: 20px;
}

.subGenreInput{
   
    width:360px;
    height:55px;
    border: 1px solid black;
    border-radius: 10px;
    padding-left: 10px;
    font-family: 'Oswald';
    font-weight: 500;
    font-size:18px;
    margin-top:5px;
    color: #121212;
    
}

@media (min-width:1001px) and (max-width:1599px){
    .addForm{
        width:1010px;
    }
    .addFormHeader{
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 35px;
        color:#E6C068;
    }
    .addFormTop{
        width:870px;
        height: 400px;
        flex-direction: row;
    }
    .largeInput{
        width:475px;
        height:45px;
    }
    .addFormTopHorizontal{
        width:475px;
    }
    .addFormDropdown{
        width:157px;
    }
    .addFormDropdownTrigger{
        width:157px;
        height:45px;
    }
    .smallInput{
        width:123px;
        height:45px;
    }
    .addFormImage{
        width:270px;
        height:270px;
        margin-top: 70px;
    }
    .addFormBottom{
        width:700px;
    }
    .addFormEditor{
        width:700px;
    }
    .addImage{
        color:#2F2F2F;
    }
    .addReviewButton{
        margin-top: 80px;
    }
}