.RichEditor-root {
    background: #fff;
    border:none;
    
    
    
  }
  
  .RichEditor-editor {
    border:none;
    cursor: text;
    font-size: 16px;
    margin-top: 10px;
    
    width:100%;
  }
  
  .RichEditor-editor .public-DraftEditorPlaceholder-root,
  .RichEditor-editor .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 15px;
  }
  
  .RichEditor-editor .public-DraftEditor-content {
    min-height: 100px;
  }
  
  .RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
  }
  
  .RichEditor-editor .RichEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: 'Hoefler Text', 'Georgia', serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
  }
  
  .RichEditor-editor .public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
    font-size: 16px;
    padding: 20px;
  }
  
  .RichEditor-controls {
    font-family: 'Helvetica', sans-serif;
    font-size: 16px;
    
    user-select: none;
    background-color: #E6C068;
    
    display: flex;
    text-align: center;
    justify-content: flex-start;
    align-items: center;
    width:100%;
    height:100px;
    border-radius: 1vw 1vw 0 0;
    
  }
  
  .RichEditor-styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
  }
  .RichEditor-styleButton:first-child{
    margin-left: 50px;
  }
  .RichEditor-styleButton > img{
    width:32px;
  }
  
  .RichEditor-activeButton {
    color: #5890ff;
    background-color: #E8E8E8;
    border-radius: 5px;
  }
  .DraftEditor-root{
    display: flex;
    /*font-family: 'Noto Sans';
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;*/
    justify-content: center;
  }
  .DraftEditor-editorContainer{
    display: flex;
    width:inherit;
  }
  .DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content{
    width: inherit;
  }
  .RichEditor-editor .public-DraftEditor-content{
    width:inherit;
    height: inherit;
  }
  .RichEditor-editor .public-DraftEditorPlaceholder-root, .RichEditor-editor .public-DraftEditor-content{
    width:inherit;
    margin:0;
    padding:0;
  }

  .addFormEditor.DraftEditor-root{
    width:740px;
  }