.carouselWrapper{
    display: flex;
    flex-direction: column;
    width:100%;
    height: 1000px;
    align-items: center;
    justify-content: center;
    background-color: #121212;
    margin-top: 6%;
    
}

.carouselHeader{
    font-family: 'Oswald';
    font-size: 42px;
    font-weight: 700;
    color:#E6C068;
}

.carousel{
    
}

.carousel.carousel-slider{
    width:100px;
    
}

.carousel .slide{
    
    height: 500px;
    width: 470px;
    
}

.carousel .slide img{
    height:400px;
    width:400px;
    
}

.carouselItem{
    display: flex;
    width:100%;
    height: 100%;
    
}

.slideLeft{
    display: flex;
    flex-direction: column;
    flex:1;
    background-color: #2F2F2F;
    color:#E8E8E8;
    border-radius: 20px 0 0 20px;
    align-items: flex-start;
}

.slideLeft > p{
    margin-left: 70px; 
}

.slideLeftBand{
    font-size:40px;
    font-weight: bold;
    margin-top: 40px;
    
}

.slideLeftRecord{
    font-size:32px;
    font-weight: bold;
    margin-bottom: 20px;
}

.slideLeftShortDesc{
    margin-top: 20px;
    overflow-wrap: break-word;
    width:50%;
    text-align: left;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.slideLeftButton{
    margin-left: 70px;
    margin-top: 50px;
    width:100px;
    height:30px;
    border:none;
    background-color: #FFE4BC;
    color:#121212;
    cursor: pointer;
}

.slideRight{
    display: flex;
    flex:1;
    align-items: center;
    justify-content: center;
    background-color: #2F2F2F;
    border-radius:0 20px 20px 0;
}

.slideRight > img{
    
}

.carousel .control-next.control-arrow{
    border-radius:0 20px 20px 0;
}

.carousel .control-prev.control-arrow{
    border-radius: 20px 0 0 20px;
}

@media (max-width:600px){
    .carouselWrapper{
        height:800px;
    }
    .carouselHeader{
        font-family: 'Oswald';
        font-size:25px;
        margin-bottom: 23px;
        margin-top: -25px;
    }

    .mobileSliderImage{
        width:300px;
    }
    .mobileSliderImage > img{
        width:300px;
    }
    .carousel.carousel-slider{
        width:300px;
        
    }
    .carousel.carousel-slider{
        width:100px;
        
    }
    
    .carousel .slide{
        
        height: 300px;
        width: 30px;
        
    }
    
    .carousel .slide img{
        height:300px;
        width:300px;
        
    }

    .mobileSlideDescription{
        margin-top: 20px;
        overflow-wrap: break-word;
        width:300px;
        height:285px;
        text-align: left;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp:12;
        -webkit-box-orient: vertical;
        color:#E8E8E8;
        font-family: 'Noto Sans';
        font-size: 15px;
        line-height: 22px;
        
    }
    .mobileSlideShortDesc{
        height:inherit
    }
    .mobileBandName{
        font-family: 'Oswald';
        font-weight: bold;
        font-size: 22px;
    }

    .mobileRecordName{
        font-family: 'Oswald';
        font-size: 16px;
        margin-bottom: 20px;
    }

    .mobileSliderReadMoreContainer{
        width:300px;
        margin-top:20px;
        display: flex;
        align-items: flex-start;
        
    }

    .mobileSliderReadMore{
        display: flex;
        align-self: flex-start;
        align-items: center;
        justify-content: center;
        width:143px;
        height:43px;
        background-color:#E6C068;
        z-index: 10;
        border-radius: 10px;
        text-decoration: none;
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 16px;
        
    }

    .mobileSliderReadMoreLink{
        text-decoration: none;
        color:#121212;
    }
    .mobileSliderReadMoreLink:visited{
        color:#121212;
        text-decoration: none;
    }
    .carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover{
        background: #E6C068;
    }
    
}

@media (min-width:601px){
    .carouselWrapper{
        height:600px;
        padding-bottom: 200px;
        padding-top: 200px;
    }
    .carouselHeader{
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 30px;
    }
    .slideLeft > p{
        margin-left: 50px; 
    }
    
    .slideLeftBand{
        text-align: start;
        font-size:30px;
        margin-top: 63px;
    }
    .slideLeftRecord{
        font-size: 22px;
        margin-bottom: 0;
    }
    .slideLeftShortDesc{
        width:230px;
        height:122px;
        font-family: 'Noto Sans';
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        margin-top: 16px;
        overflow-wrap: break-word;
        
        text-align: left;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
    }
    .carousel .slide img{
        height:275px;
        width:275px;
        margin-right: 50px;
    }
    .carousel .slide{
        height: 400px;
    }
    .slideLeftButton{
        width:170px;
        height:43px;
        border-radius: 5px;
        margin-left: 50px;
        margin-top: 30px;
        background-color: #E6C068;
        font-size: 16px;
        font-family: 'Oswald';
        font-weight: 600;
    }
    .sliderReadMoreLink{
        width:210px;
        height:60px;
        text-decoration: none;
    }
    .slideLeftButton{
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        width:168px;
        height:43px;
       
        margin-top: 30px;
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 16px;
    }
    .carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover{
        background: #E6C068;
    }
}
@media (min-width:1001px) and (max-width:1599px){
    .carouselWrapper{
        height:900px;
        padding-bottom: 200px;
        padding-top: 200px;
    }
    .carouselHeader{
        font-size: 45px;
        font-weight: 700;
    }
    .carousel-root{
        
        height:inherit
        
    }
    .carouselItem{
        
    }
    .carousel.carousel-slider{
        height: inherit;
    }
    .carousel .slide{
        height: inherit;
    }
    .carousel .slider-wrapper{
        height: inherit;
    }
    .carousel .slider.animated{
        height: inherit;
    }
    .slideLeft > p {
        margin-left: 70px;
    }
    .sliderReadMoreLink{
        width:210px;
        height:60px;
        text-decoration: none;
    }
    .slideLeftButton{
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        width:186px;
        height:46px;
        margin-left: 70px;
        margin-top: 50px;
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 17px;

        
    }
    .slideLeftBand{
        font-family: 'Oswald';
        font-weight: 700;
        font-size: 40px;
        margin-top: 60px;
    }
    .slideLeftRecord{
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 30px;
    }
    .slideLeftShortDesc{
        width:425px;
        height:250px;
        font-family: 'Noto Sans';
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        line-height: 1.6em;
        height: 11.4em;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
    }
    .carousel .slide img{
        width:400px;
        height:400px;
        margin-right: 180;
    }
    .carousel .control-dots .dot{
        background: #121212;
        box-shadow: none;
        opacity: 1;
        width:15px;
        height:15px;
    }
    .carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover{
        background: #E6C068;
    }
}

@media (min-width:1600px){
    .carouselWrapper{
        height:1100px;
    }
    .carouselHeader{
        font-size: 45px;
        font-weight: 700;
    }
    .carousel-root{
        
        height:inherit
        
    }
    .carouselItem{
        
    }
    .carousel.carousel-slider{
        height: inherit;
    }
    .carousel .slide{
        height: inherit;
    }
    .carousel .slider-wrapper{
        height: inherit;
    }
    .carousel .slider.animated{
        height: inherit;
    }
    .slideLeft > p {
        margin-left: 180px;
    }
    .sliderReadMoreLink{
        width:210px;
        height:60px;
        text-decoration: none;
    }
    .slideLeftButton{
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        width:210px;
        height:60px;
        margin-left: 180px;
        margin-top: 50px;
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 20px;
    }
    .slideLeftBand{
        font-family: 'Oswald';
        font-weight: 700;
        font-size: 45px;
        margin-top: 125px;
    }
    .slideLeftRecord{
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 33px;
    }
    .slideLeftShortDesc{
        width:570px;
        height:215px;
        font-family: 'Noto Sans';
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        
        
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
    }
    .carousel .slide img{
        width:500px;
        height:500px;
        margin-right: 180;
    }
    .carousel .control-dots .dot{
        background: #121212;
        box-shadow: none;
        opacity: 1;
        width:20px;
        height:20px;
    }
    .carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover{
        background: #E6C068;
    }
}



