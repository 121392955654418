.about{
    display:flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    background-color: #121212;
}

.aboutWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:4vh;
    width:86.3vw;
    height:100vh;
    background-color: #2F2F2F;
}

.aboutContentHeader{
    width:100%;
    display: flex;
    margin-top: 2vh;;
    
}

.aboutHeaderLeft{
    display: flex;
    flex:1;
    
    align-items: center;
}

.aboutBackButton{
    
    color:#E8E8E8;
    font-size:2vw;
    font-weight: bold;
    
    cursor: pointer;
    
    
    
}



.aboutHeaderRight{
    display: flex;
    justify-content: flex-end;
    flex:1;
    
}



.aboutContent{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top:4vh;
    width:80vw;
    height:80vh;
    background-color: #2F2F2F;
}



.aboutDesc{
    
    align-self: flex-start;
    line-height: 2.5vh;
    width:40vw;
    color:#E8E8E8;
    font-size: 1vw;
}

.aboutImages{
    align-self: flex-start;
    
}

.aboutProfilePic{
    width:15vw;
    height:15vw;
    border-radius: 50%;
    
    object-fit: cover;
    border:5px solid #E6C068;

}

.aboutFavoritesSection{
    width:100%;
}

.aboutFavoritesText{
    color:#E8E8E8;
    font-size: 1.5vw;
    font-weight: bold;
    margin-left: 3.2vw;
}

.aboutFavorites{
    display: flex;
    margin-top:2vh;
}

.aboutFavoriteCard:first-child{
    margin-left:3.2vw;
}

.aboutFavoriteCard{
    margin-left:1.5vw;
}

@media (max-width:600px){
    .aboutWrapper{
        background-color: #121212;
        width:310px;
        height:auto;
        margin-top:0;
        
    }
    .aboutHeader{
        align-self: center;
        font-size: 25px;
        flex:0;
        font-family: 'Oswald';
        font-weight: bold;
        color:#E6C068;
    }

    .aboutContent{
        flex-direction: column;
        height:1350px;
        margin-top: 10px;
        margin-bottom:0;
        width:inherit;
        height:auto;
        background-color: #121212;
    }
    .aboutDesc{
        width:inherit;
        height:auto;
        font-size: 15px;
        font-family: 'Noto Sans';
        line-height: 22px;
        background-color: #121212;
        
    }
    .aboutProfiles{
        display: flex;
        flex-direction: row;
        width:310px;
        justify-content: space-between;
        height:250px;
        margin-top: 80px;
        
    }
    .aboutProfile{
        display: flex;
        flex-direction: column;
        width:150px;
        height: 200px;
        align-items: center;
        background-color: #2F2F2F;
        border-radius: 10px;
        
    }
    .aboutProfilePic{
        width: 95px;
        height: 95px;
        border-radius: 50%;
        margin-left: 2vw;
        object-fit: cover;
        border: 3px solid #E6C068;
        margin-top: 20px;
    }
    .aboutProfileName{
        text-align: center;
        font-family: 'Oswald';
        font-size: 14px;
        font-weight: 700;
        color:#E8E8E8;
        margin-top:16px;
    }
    .aboutProfileEmail{
        text-align: center;
        color:#E8E8E8;
        font-family: 'Oswald';
        font-weight: 300;
        font-size: 10px;
        margin-top: 2px;
    }
}

@media (min-width:601px){
    .aboutWrapper{
        background-color: #121212;
        width:650px;
        height:auto;
        margin-top:0;
        
    }
    .aboutHeader{
        align-self: flex-start;
        font-size: 32px;
        flex:0;
        font-family: 'Oswald';
        font-weight: 700;
        color:#E6C068;
    }

    .aboutContent{
        flex-direction: column;
        height:1350px;
        margin-top: 10px;
        width:inherit;
        height:auto;
        background-color: #121212;

    }
    .aboutDesc{
        width:inherit;
        height:auto;
        font-size: 16px;
        font-family: 'Noto Sans';
        font-weight: 500;
        line-height: 24px;
        background-color: #121212;
    }
}

@media (min-width:1001px) and (max-width:1599px){
    .aboutWrapper{
        width:1010px;
        padding-bottom: 200px;
        background-color: #212121;
        border-radius: 20px;
        margin-top: 40px;
    }
    .aboutContentHeader{
        display: flex;
        flex-direction: row;
        width:900px;
        margin-top: 30px;
    }
    .aboutHeaderLeft{
        display: flex;
        flex:1;
        
    }
    .aboutHeaderMid{
        display: flex;
        flex:1;
        
        justify-content: center;
    }
    .aboutHeaderRight{
        display: flex;
        
        align-items: center;
        flex:1;
        
    }
    .aboutHeader{
        flex:none;
        width:140px;
        text-align: center;
        font-family: 'Oswald';
        font-weight: 700;
        color:#E6C068;
        font-size: 35px;
        margin-top: -5px;
    }
    .aboutBackButton{
        margin-left:0;
    }
    .aboutContent{
        display: flex;
        flex-direction: row;
        background-color: #212121;
        width:900px;
        height:900px
        
        
        
    }
    .aboutDesc{
        display: flex;
        background-color: #212121;
        margin-top: 80px;
        margin-left: 0;
        width:535px;
        font-family: 'Noto Sans';
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        
    }
    .aboutProfiles{
        display: flex;
        flex-direction: column;
        flex:1;
        align-items: flex-end;
        height:700px;
        
        
        
    }
    .aboutProfile{
        display: flex;
        flex-direction: column;
        width:236px;
        height: 322px;
        align-items: center;
        background-color: #121212;
        border-radius: 10px;
        
    }
    .aboutProfilePic{
        width: 150px;
        height: 150px;
        border-radius: 50%;
        margin-left: 0;
        object-fit: cover;
        border: 5px solid #E6C068;
        margin-top: 34px;
    }
    .aboutProfileName{
        text-align: center;
        font-family: 'Oswald';
        font-size: 20px;
        font-weight: 700;
        color:#E8E8E8;
        margin-top:24px;
    }
    .aboutProfileEmail{
        text-align: center;
        color:#E8E8E8;
        font-family: 'Oswald';
        font-weight: 300;
        font-size: 18px;
        margin-top: 5px;
    }
    .aboutFavoritesSection{
        width:1010px;
        margin-top: 80px;
    }
    .aboutFavoritesText{
        font-family: 'Oswald';
        font-weight: 700;
        font-size: 35px;
        color:#E6C068;
        margin-left: 0;
        text-align: center;
    }
    .aboutFavorites{
        margin-top:65px;
    }
    .aboutFavoriteCard:first-child{
        margin-left: 0;
    }
    .aboutEditLink{
        width:210px;
        height:60px;
        display: flex;
        
        
        background-color: #E6C068;
        color:#121212;
        text-decoration: none;
        justify-content: center;
        align-items: center;
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 20px;
        border-radius: 5px;
       
    }
}

@media (min-width:1600px){
    .aboutWrapper{
        margin-top: 50px;
        width:1640px;
        background-color: #212121;
        border-radius: 20px;
        padding-bottom: 100px;
    }
    .aboutContentHeader{
        display: flex;
        flex-direction: row;
        width:1500px;
        
    }
    .aboutHeaderLeft{
        display: flex;
        flex:1;
        
    }
    .aboutHeaderMid{
        display: flex;
        flex:1;
        
        justify-content: center;
    }
    .aboutHeaderRight{
        display: flex;
        
        align-items: center;
        flex:1;
        
    }
    .aboutHeader{
        flex:none;
        width:140px;
        text-align: center;
        font-family: 'Oswald';
        font-weight: 700;
        color:#E6C068;
        font-size: 45px;
        margin-top: -5px;
    }
    .aboutBackButton{
        margin-left:0;
    }
    .aboutContent{
        flex-direction: row;
        background-color: #212121;
    }
    .aboutDesc{
        display: flex;
        
        background-color: #212121;
        margin-top: 80px;
        margin-left: 73px;
        width:746px;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
    }
    .aboutProfiles{
        display: flex;
        flex-direction: row;
        flex:1;
        justify-content: flex-end;
        height:992px;
        margin-top: 80px;
        
    }
    .aboutProfile{
        display: flex;
        flex-direction: column;
        width:285px;
        height: 430px;
        align-items: center;
        background-color: #2F2F2F;
        border-radius: 10px;
        
    }
    .aboutProfilePic{
        width: 200px;
        height: 200px;
        border-radius: 50%;
        
        object-fit: cover;
        border: 5px solid #E6C068;
        margin-top: 45px;
    }
    .aboutProfileName{
        text-align: center;
        font-family: 'Oswald';
        font-size: 29px;
        font-weight: 700;
        color:#E8E8E8;
        margin-top:34px;
    }
    .aboutProfileEmail{
        text-align: center;
        color:#E8E8E8;
        font-family: 'Oswald';
        font-weight: 300;
        font-size: 22px;
        margin-top: 10px;
    }
    .aboutFavoritesSection{
        width:1450px;
        margin-top: 80px;
    }
    .aboutFavoritesText{
        font-family: 'Oswald';
        font-weight: 700;
        font-size: 45px;
        color:#E6C068;
        margin-left: 0;
        text-align: center;
    }
    .aboutFavorites{
        margin-top:65px;
        justify-content: space-between;
    }
    .aboutFavoriteCard:first-child{
        margin-left: 0;
    }
    .aboutEditLink{
        width:210px;
        height:60px;
        display: flex;
        
        
        background-color: #E6C068;
        color:#121212;
        text-decoration: none;
        justify-content: center;
        align-items: center;
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 20px;
        border-radius: 5px;
       
    }
}
