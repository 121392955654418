.login{
    width:100%;
    height:100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #121212;
}
.loginWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:960px;
    height:850px;
    background-color: #212121;
    border-radius: 20px;
}
.loginWrapper > img {
    width:185px;
    height:185px;
    margin-top:-92px;
}
.loginInnerWrapper{
    width:780px;
    height:610px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #121212;
    border-radius: 20px;
    margin-top: 50px;
    font-family: 'Oswald';

}

.loginForm{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 46px;
    color:#E6C068;
}

.loginHeader{
    font-weight: 600;
    font-size: 45px;
    font-weight: bold;
    color:#E6C068;
    margin-top: 32px;
    
}

.loginFieldDesc{
    font-weight: 600;
    font-size: 22px;
    align-self: flex-start;

}

.loginForm > input {
    width:425px;
    height:55px;
    padding-left: 10px;
    border:1px solid black;
    border-radius: 5px;
    margin-top: 15px;
    font-family: 'Noto Sans';
    font-weight: 500;
    font-size: 18px;
    color:#737373;
}

.loginForm > button{
    margin-top:20px;
    width:425px;
    height:55px;
    border:none;
    background-color: #E6C068;
    color: #121212;
    cursor: pointer;
    font-family: 'Oswald';
    font-weight: 600;
    font-size: 20px;
    border-radius: 5px;
    margin-top: 60px;
}

@media (min-width:1001px) and (max-width:1599px){
    .loginWrapper{
        width:860px;
        height:650px;
    }
    .loginInnerWrapper{
        width:550px;
        height:430px;
    }
    .loginHeader{
        font-size: 31px;
    }
    .loginFieldDesc{
        font-size: 16px;
    }
    .loginForm{
        width:300px;
        margin-top: 25px;
    }
    .loginForm > input{
        width:300px;
        height:40px;
        font-size: 14px;
    }
    .loginForm > button{
        width:300px;
        height:40px;
        margin-top: 40px;
    }
}