.footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    height:50vh;
    
    /*margin-top:30vh;*/
}

.footerTopSection{
    width:100%;
    height:45vh;
    background-color: #FFE4BC;
}

.footerLinks{
    display: flex;
    margin-left: 6vw;
}

.footerInfo{
    
    margin-top: 2vh;
    margin-left: 6vw;
    width:30vw;
    line-height: 2vh;
}

.footerContact{
    margin-top: 4vh;
    margin-left: 6vw;
}

.footerEmail{
    margin-top: 2vh;
}

.footerBottomSection{
    width:100%;
    height:5vh;
    background-color: #121212;
    color:white;
    display: flex;
    
    justify-content: center;
}

.footerList{
    display:flex;
    list-style: none;
    align-items: center;
    margin-top: 2vh;
}

.footerListItem{
    font-size:1vw;
    margin-left:68px;
    cursor: pointer;
    color: black;
}

.footerLogo{
    margin-top: 2vh;
    width:260px;
    height:95px;
}

.footerLogo > img {
    width:260px;
    height:95px;
    
}

.footerCopyright{
    margin-top:2vh;
}

@media (max-width:600px){
    .footer{
        margin-top: 0;
        height:auto;
        background-color: #121212;
        border-top:2px solid #E6C068;
        margin-top:125px;
    }
    .mobileFooterLogo{
        width:120px;
        height:120px;
        border-radius: 50%;
        margin-top:-60px;
        
    }
    .mobileFooterLogo > img{
        width:120px;
        height:120px;
        border-radius: 50%;
    }
    .mobileFooterInfo{
        margin-top: 20px;
        overflow-wrap: break-word;
        width:300px;
        height:600px;
        text-align: left;
        overflow: hidden;
        display: -webkit-box;
        
        -webkit-box-orient: vertical;
        color:#E8E8E8;
        
    }
    .mobileFooterHeader{
        text-align: center;
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 25px;
        color:#E6C068;
    }
    .mobileFooterText{
        margin-top:20px;
        font-family: 'Noto Sans';
        font-weight: 500;
        font-size: 15px;
        text-align: center;
        line-height: 22px;
    }
    .mobileFooterInfo > hr {
        margin-top: 30px;
        width:120px;
        margin-left:auto;
        margin-right:auto;
        color:#E6C068;
        background-color: #E6C068;
    }
    .mobileFooterHorizontalLine{
        width:120px;
        border-top: 2px solid #E6C068;
        margin-top: 30px;
        margin-left:auto;
        margin-right:auto;
    }
    .mobileFooterContactHeader{
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
        font-family: 'Oswald';
        font-size: 25px;
        color:#E6C068;
    }
    .mobileFooterEmail{
        text-align: center;
        font-family: 'Oswald';
        font-weight: 300;
        font-size: 16px;
        margin-bottom: 10px;

    }
    .mobileFooterCopyright{
        background-color: #E6C068;
        width:100%;
        height:40px;
        font-family: 'Noto Sans';
        font-size: 8px;
        text-align: center;
        padding:15px 0;
        margin-top: 30px;
    }
}

@media (min-width:601px){
    .footer{
        margin-top: 170px;
        height:auto;
        background-color: #121212;
        border-top:2px solid #E6C068;
    }
    .mobileFooterLogo{
        width:160px;
        height:160px;
        border-radius: 50%;
        margin-top:-80px;
    }
    .mobileFooterLogo > img{
        width:160px;
        height:160px;
        border-radius: 50%;
    }
    .mobileFooterInfo{
        margin-top: 20px;
        overflow-wrap: break-word;
        width:530px;
        height:520px;
        text-align: left;
        overflow: hidden;
        display: -webkit-box;
        
        -webkit-box-orient: vertical;
        color:#E8E8E8;
        text-align: center;
    }
    .mobileFooterHeader{
        text-align: center;
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 32px;
        color:#E6C068;
    }
    .mobileFooterText{
        margin-top:20px;
        font-family: 'Noto Sans';
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }
    .mobileFooterInfo > hr {
        margin-top: 50px;
        width:240px;
        margin-left:auto;
        margin-right:auto;
        color:#E6C068;
    }
    .mobileFooterHorizontalLine{
        width:240px;
        border-top: 2px solid #E6C068;
        margin-top: 30px;
        margin-left:auto;
        margin-right:auto;
    }
    .mobileFooterContactHeader{
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 32px;
        color:#E6C068;
    }
    .mobileFooterEmail{
        text-align: center;
        font-family: 'Oswald';
        font-weight: 300;
        font-size: 18px;
        margin-bottom: 12px;
    }
    .mobileFooterCopyright{
        background-color: #E6C068;
        width:100%;
        height:80px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Noto Sans';
        font-weight: 700;
        font-size: 13px;
        text-align: center;
        padding:15px 0;
        margin-top: 30px;
    }
}

@media (min-width:1001px) and (max-width:1599px){
    .footer{
        height:770px;
        border-top:2px solid #E6C068;
        margin-top:150px;
    }
    .footerLogo{
        width:160px;
        height:160px;
        margin-top:-80px;

    }
    .footerLogo > img {
        width:160px;
        height:160px;
    }
    .footerInfo{
        width:630px;
        height:260px;
        text-align: center;
        font-family: 'Noto Sans';
        font-weight: 500;
        font-size:18px;
        line-height: 26px;
        color:#E8E8E8;
        margin-left: 0;
        margin-top:20px;
    }
    .footerInfoHeader{
        font-family:'Oswald';
        font-weight: 500;
        font-size: 40px;
        color:#E6C068;
        height:90px;
        margin-top:20px;
    }
    .mobileFooterHorizontalLine{
        width:275px;
    }
    .footerContact{
        display: flex;
        flex-direction: column;
        color:#E8E8E8;
        margin-left:0;
        margin-top: 24px;
        justify-content: center;
        align-items: center;
    }
    .footerContactHeader{
        font-family: 'Oswald';
        font-weight: 500;
        font-size: 30px;
        color:#E6C068;
        height:50px;
    }
    .footerEmails{
        display: flex;
        flex-direction: row;
        width:360px;
        height:40px;
        align-items: center;
        justify-content: space-between;
    }
    .footerInvisibleDiv{
        width:12px;
    }
    .footerEmail{
        font-family: 'Oswald';
        font-weight: 300;
        font-size: 20px;
        margin-top: 0;
        
    }
    .footerDotSeparator{
        width:11px;
        height:11px;
        background-color: #E6C068;
        border-radius: 50%;
        text-align: center;
        
    }
    .footerBottomSection{
        background-color: #E6C068;
        width:100%;
        height:80px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Noto Sans';
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        
        margin-top: 100px;

    }
    .footerCopyright{
        font-family: 'Noto Sans';
        font-weight: 700;
        font-size: 16px;
        color:#121212;
        margin-top: 0;
    }
}

@media (min-width:1600px){
    .footer{
        height:770px;
        border-top:2px solid #E6C068;
        margin-top: 200px;
    }
    .footerLogo{
        width:185px;
        height:185px;
        margin-top:-92px;

    }
    .footerLogo > img {
        width:185px;
        height:185px;
    }
    .footerInfo{
        width:860px;
        height:270px;
        text-align: center;
        font-family: 'Noto Sans';
        font-weight: 500;
        font-size:20px;
        line-height: 30px;
        color:#E8E8E8;
        margin-left: 0;
        margin-top:20px;
    }
    .footerInfoHeader{
        font-family:'Oswald';
        font-weight: 500;
        font-size: 45px;
        color:#E6C068;
        height:90px;
        margin-top:20px;
    }
    .mobileFooterHorizontalLine{
        width:275px;
    }
    .footerContact{
        display: flex;
        flex-direction: column;
        color:#E8E8E8;
        margin-left:0;
        margin-top: 24px;
        justify-content: center;
        align-items: center;
    }
    .footerContactHeader{
        font-family: 'Oswald';
        font-weight: 500;
        font-size: 40px;
        color:#E6C068;
        height:90px;
    }
    .footerEmails{
        display: flex;
        flex-direction: row;
        width:420px;
        height:40px;
        align-items: center;
        justify-content: space-between;
    }
    .footerInvisibleDiv{
        width:10px;
    }
    .footerEmail{
        font-family: 'Oswald';
        font-weight: 300;
        font-size: 22px;
        margin-top: 0;
        
    }
    .footerDotSeparator{
        width:17px;
        height:17px;
        background-color: #E6C068;
        border-radius: 50%;
        text-align: center;
        
    }
    .footerBottomSection{
        background-color: #E6C068;
        width:100%;
        height:80px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Noto Sans';
        font-weight: 700;
        font-size: 13px;
        text-align: center;
        
        margin-top: 60px;

    }
    .footerCopyright{
        font-family: 'Noto Sans';
        font-weight: 700;
        font-size: 16px;
        color:#121212;
        margin-top: 0;
    }
}