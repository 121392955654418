.searchBar{
    display: flex;
    flex-direction: row;
    position: relative;
    
    
    width:360px;
    height:67px;
    
    
}

.searchBarInput{
    width:100%;
    height:100%;
    border:none;
    border-radius: 5px;
    font-family: 'Noto Sans';
    font-weight: 500;
    font-size: 16px;
    color:#2F2F2F;
    padding-left: 10px;
}

.searchBarLink{
    width:67px;
    height: 100%;
    border-radius: 0 5px 5px 0;
    background-color: #E6C068;
    position: absolute;
    right:0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.searchBarIcon{
   
    
}