.recordCard{
    background-color: #f3f3f3;
    display: flex;
    text-decoration: none;
}
.recordCard:visited{
    text-decoration: none;
}

.recordCardLabel{
    display: flex;
    align-self: flex-end;
    margin-top: 1.5vh;
    background-color: #E6C068;
    width:3.906vw;
    height:30px;
    font-family: 'Oswald';
    font-weight: 600;
    font-size: 0.729vw;
    align-items: center;
    justify-content: center;
    color:#212121;

}

.recordCardNameRecord{
    align-self:center;
    background-color: #FFFFFFCC;
    /*opacity: .5;*/
    width:100%;
    height:25%;   
}
.recordCardName{
    font-family: 'Oswald';
    font-size: 24px;
    font-weight: 700;
    margin-top:10px;
    margin-left:20px;
    
}

.recordCardRecord{
    /*font-size: 18px;*/
    font-family: 'Oswald';
    font-weight: 500;
    margin-left:20px;
}

@media (max-width:600px){
    .recordCardLabel{
        width:50px;
        height:15px;
        font-size: 8px;
        font-family: 'Oswald';
        font-weight: 500;
    }
    .recordCardName{
        margin-top:5px;
        margin-left:10px;
    }
    .recordCardRecord{
        margin-left:10px;
    }
}

@media (min-width:601px){
    .recordCardLabel{
        width:60px;
        height:18px;
        font-size:11px;
    }
}

@media (min-width:1001px) and (max-width:1599px){
    .recordCardNameRecord{
        display: flex;
        flex-direction: column;
        
        align-self:center;
        
        background-color: #FFFFFFCC;
        /*opacity: .5;*/
        width:100%;
        height:25%;   
    }
    .recordCardName{
        font-family: 'Oswald';
        font-size: 15px;
        font-weight: 700;
        margin-top:0;
        margin-left:17px;
        
    }
    
    .recordCardRecord{
        /*font-size: 18px;*/
        font-family: 'Oswald';
        font-weight: 500;
        margin-left:17px;
    }
}
