.editFavoritesDnDArea{
    display: flex;
    flex-direction: column;
    width:100%;
    
    margin-top: 80px;
    

}

.editFavoritesDnDSource{
    display: flex;
    width:1550px;
    height: 240px;
    align-self: center;
    
    margin-top: 100px;
    overflow-y: hidden;
    overflow-x: auto;
    
}

.editFavoritesDnDTarget{
    display: flex;
    flex-direction: column;
    align-self: center;
    width:1640px;
    border:10px solid #121212;
    border-radius: 20px;
    margin-top: 80px;
    align-items: center;
    padding-bottom: 100px;
}

.editFavoritesTargetText{
    display: flex;
    width:100%;
    
    align-items: center;
    justify-content: center;
    color:#E8E8E8;
    font-family: 'Noto Sans';
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 45px;
}

.editFavoritesTargetBoxes{
    display: flex;
    flex-direction: row;
    width:1550px;
    
    justify-content: space-between;
    
    
}



/*.sourceBoxWrapper:first-child{
    margin-left:3.2vw;
}*/

.sourceBoxWrapper{
    margin-left:1vw;
    width:100%;
}

/*.editFavoriteCard:first-child{
    margin-left: 1vw;
}*/



.editRecordSource{
    margin-left: 2.5vw;
}

@media (min-width:1001px) and (max-width:1599px){
    .editFavoritesDnDTarget{
        width: 1100px;
        padding-bottom: 50px;
    }
    .editFavoritesDnDSource{
        width: 1000px;
        margin-top: 50px;
    }
    .editFavoritesTargetBoxes{
        width:1000px;
        
    }
}