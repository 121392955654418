.searchResult{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.recordsSearchText{
    display: flex;
    font-family: 'Oswald';
    font-weight: 700;
    font-size:16px;
    color:#E6C068;
    
}

.searchResult > h1{
    color:#E8E8E8;
}

@media (max-width:600px){
    .recordsSearchText{
        font-weight: 500;
        font-size: 18px;
    }
}

@media (min-width:601px){
    .recordsSearchText{
        font-weight: 500;
        font-size: 27px;
    }
}

@media (min-width:1001px) and (max-width:1599px){
    .recordsSearchText{
        font-family: 'Oswald';
        font-weight: 700;
        font-size: 30px;
        margin-top: 30px;
        align-self: center;
    }
}

@media (min-width:1600px){
    .recordsSearchText{
        font-family: 'Oswald';
        font-weight: 700;
        font-size: 45px;
        margin-top: 35px;
        align-self: center;
    }
}