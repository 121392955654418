.dropdown{
    
}

.recordsDropdownCurrentSort{
    display: flex;
    background-color: #E6C068;
    color:black;
    cursor: pointer;
    height:30px;
    align-items: center;
    
    
    font-family: 'Oswald', medium;
    position: relative;
    border-radius: 2px;
}

.recordsDropdownArrow{
    margin-right:0.5vw;
    margin-left:auto;
}

.sortMenuListWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #E8E8E8;
    font-family: 'Oswald', medium;
    z-index: 10;
    height:100vh;
    width:70vw;
    position: absolute;
    top:0;
    
}

.sortMenuList{
    width:180px;
}

.sortMenuList > hr{
    color:#E6C068;
    margin-bottom: 22px;
}

.sortMenuHorizontalLine{
    width:100%;
    border-top: 2px solid #E6C068;
    margin-bottom: 22px;
}

.sortMenuList > p{
    font-size: 20px;
    font-family: 'Oswald';
    margin-bottom: 12px;
}

.sortMenuListButton{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E6C068;
    color:#121212;
    width:140px;
    height:40px;
    border-radius: 5px;
    font-size: 16px;
    font-family: 'Oswald';
    font-weight: 500;
    margin-top: 20px;
    
}

@media (max-width:600px){
    .sortMenuContainer{
        height:100vh;
        width:70vw;
        background-color: #121212;
        z-index: 10;
        position: absolute;
        top:0;
    }
    .sortMenuListWrapper{
        height:100vh;
        background-color: #121212;
        color:#E8E8E8;
        
    }
    .sortMenuList:first-child{
        margin-top: 70px;
    }

    /* The container */
    .container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 26px;
        cursor: pointer;
        font-size: 18px;
        font-family: 'Oswald';
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
  
    /* Hide the browser's default radio button */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    
    /* Create a custom radio button */
    .checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #121212;
        border-radius: 50%;
        border:1px solid #E6C068;
    }
    
    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
        
    }
    
    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
        
        
    }
    
    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
        
        
    }
    
    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
        display: block;
        
    }
    
    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
        top: 2px;
        left: 2px;
        width:14px;
        height:14px;
        border-radius: 50%;
        background: #E6C068;
        
    }
    .sortMenuCloseIcon{
        margin-left: -8px;
        margin-bottom: 30px;
        width:30px
    }
}

.sortMenuItem{
    color:#E8E8E8;
    cursor: pointer;
    padding:0.5vh 0 0.5vh 0;
    z-index: 10;
    margin-left:40px;
    margin-bottom: 25px;
    font-family: 'Oswald';
    font-size:22px;
}



.menuItem:hover{
    
   
}

.mobileActive{
    color:#E6C068;
 }

 @media (min-width:601px){
    .sortMenuContainer{
        height:100vh;
        width:70vw;
        background-color: #121212;
        z-index: 10;
        position: absolute;
        top:0;
    }
    .sortMenuListWrapper{
        height:100vh;
        background-color: #121212;
        color:#E8E8E8;
    }
    .sortMenuList:first-child{
        margin-top: 70px;
    }

    /* The container */
    .container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 26px;
        cursor: pointer;
        font-size: 18px;
        font-family: 'Oswald';
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
  
    /* Hide the browser's default radio button */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    
    /* Create a custom radio button */
    .checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #121212;
        border-radius: 50%;
        border:1px solid #E6C068;
    }
    
    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
        
    }
    
    /* When the radio button is checked, add a blue background */
    .container input:checked ~ .checkmark {
        
        
    }
    
    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
        
        
    }
    
    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
        display: block;
        
    }
    
    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
        top: 2px;
        left: 2px;
        width:14px;
        height:14px;
        border-radius: 50%;
        background: #E6C068;
        
    }
    .sortMenuCloseIcon{
        margin-left: -8px;
        margin-bottom: 30px;
        width:30px
    }
 }
