.dropdown{
    
}

.recordsDropdownCurrentSort{
    display: flex;
    background-color: #E6C068;
    color:black;
    cursor: pointer;
    height:30px;
    align-items: center;
    
    
    font-family: 'Oswald', medium;
    position: relative;
    border-radius: 2px;
}

.recordsDropdownArrow{
    margin-right:0.5vw;
    margin-left:auto;
}

.dropdownList{
    list-style-type: none;
    background-color: #E8E8E8;
    font-family: 'Oswald', medium;
    z-index: 10;
}

@media (max-width:600px){
    .menuList{
        height:100vh;
    }
}

.dropdownItem{
    color:black;
    cursor: pointer;
    padding:0.5vh 0 0.5vh 0;
    z-index: 10;
}

/*.dropdownItem:hover{
    
    background-color: #E6C068;
    box-shadow: 0 5px 10px grey;
}*/

@media (min-width:1001px) and (max-width:1599px){
    .dropdownList{
        margin-top: -3px;
        width:inherit;
        /*height:210px;*/
        border-radius: 5px;
        position: relative;
        z-index: 10;
    }
    
    .dropdownItemContainer:hover{
        background-color: #E6C068;
        box-shadow: 0 5px 10px grey;
    }
    .dropdownItem{
        font-family: 'Oswald';
        font-weight: 500;
        font-size: 22px;
        color:#121212;
        margin-left:45px;
        padding-top: 10px;
        padding-bottom: 10px;

    }
}

@media (min-width:1600px){
    .dropdownList{
        margin-top: -3px;
        width:inherit;
        /*height:210px;*/
        border-radius: 5px;
        position: relative;
        z-index: 10;
    }
    
    .dropdownItemContainer:hover{
        background-color: #E6C068;
        box-shadow: 0 5px 10px grey;
    }
    .dropdownItem{
        font-family: 'Oswald';
        font-weight: 500;
        font-size: 22px;
        color:#121212;
        margin-left:45px;
        padding-top: 10px;
        padding-bottom: 10px;

    }
}
