.latestSection{
    display: flex;
    
    
    margin-top: 60px;
    width:100%;

    
}

.latestSectionLeft{
    display: flex;
    width:39vw;
    flex-direction: column;
    height:950px;
    margin-right: 3.5vw;
    margin-left: 7vw;
    background-color: #121212;
    border-radius: 20px;
    margin-bottom: 60px;

}

.latestRecords{
    display: flex;
    flex:1;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: space-evenly;
    width:100%;
      
}

.latestSectionCard {
    
    /*margin-top: 3vw;*/
}

/*.latestSectionCard:first-child  {
    margin-left:0;
}

.latestSectionCard:nth-child(3){
    margin-left:0;
}*/

.latestSectionRight{
    display: flex;
    flex-direction: column;
    width:39vw;
    height:950px;
    margin-left: 3.5vw;
    background-color: #121212;
    border-radius: 20px;

   
}

.latestSectionHeader{
    font-size: 24px;
    font-weight: bold;
    color:#E6C068;
    text-align: center;
    margin-top: 35px;
    font-family: 'Oswald';
    font-weight: 700;

}

.latestMoreButton{
    display: flex;
    width:25%;
    height: 60px;
    align-self: center;
    
    background-color:#E6C068;
    border:none;
    color: #212121;
    cursor: pointer;
    font-family: 'Oswald';
    font-weight: 600;
    font-size: 18px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    
}

@media (max-width:600px){
    .latestSection{
        flex-direction: column;
        
        align-items: center;
        margin-left: 35px;
        margin-right: 35px;
        
    }

    .latestSectionHeader{
        margin-top:20px;
        font-size: 20px;
    }

    .latestSectionLeft{
        width:306px;
        height:700px;
        margin-left: 0;
        margin-right: 0;
    }

    .latestSectionRight{
        width:306px;
        height:700px;
        margin-left: 0;
        margin-right: 0;
    }

    .latestRecords{
        flex-direction: column;
    }

    .latestMoreButton{
        width:170px;
        height:45px;
        font-size: 14px;
        margin-bottom: 20px;
    }
}

@media (min-width:601px){
    .latestSection{
        flex-direction: column;
    }
    .latestSectionLeft{
        width:650px;
        height:810px;
        margin-left: auto;
        margin-right: auto;
    }

    .latestSectionRight{
        width:650px;
        height:810px;
        margin-left: auto;
        margin-right: auto;
    }
    .latestMoreButton{
        width:170px;
        height:45px;
        font-size: 16px;
        margin-bottom: 60px;
    }
}

@media (min-width:1001px) and (max-width:1599px){
    .latestSection{
        width:1010px;
        flex-direction: row;
        justify-content: space-between;
    }
    .latestSectionLeft{
        width:470px;
        height:660px;
        margin-left: 0;
        margin-right: 0;
    }
    .latestSectionRight{
        width:470px;
        height:660px;
        margin-left: 0;
        margin-right: 0;
    }
    .latestRecordsCards{
        align-self: center;
        display: flex;
        margin-top: 37px;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;
        width:390px;
        height:390px;
        min-height: 390px;
    }
    .latestSectionCard{
        height:180px;
    }
    .latestMoreButton{
        font-family: 'Oswald';
        font-weight: 600;
        margin-top: 35px;
        font-size: 17px;
        width:186px;
        height:46px;
    }
    .latestSectionHeader{
        font-family: 'Oswald';
        font-weight: 700;
        font-size: 35px;
    }
}

@media (min-width:1600px){
    .latestSection{
        width:1640px;
        flex-direction: row;
        justify-content: space-between;
    }
    .latestSectionLeft{
        width:800px;
        height:930px;
        margin-left: 0;
        margin-right: 0;
    }
    .latestSectionRight{
        width:800px;
        height:930px;
        margin-left: 0;
        margin-right: 0;
    }
    .latestRecordsCards{
        align-self: center;
        display: flex;
        margin-top: 37px;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;
        width:660px;
        height:660px;
        min-height: 660px;
    }
    .latestSectionCard{
        height:315px;
    }
    .latestMoreButton{
        font-family: 'Oswald';
        font-weight: 600;
        margin-top: 35px;
        font-size: 20px;
        width:210px;
        height:57px;
    }
    .latestSectionHeader{
        font-family: 'Oswald';
        font-weight: 700;
        font-size: 35px;
    }
}


/*.latestSection{
    display: flex;
    flex-direction: column;
    margin-top: 10%;;

}


.latestHeader{
    font-size: 40px;
    font-weight: bold;
    align-self: center;
    margin-top:15px;
}

.latestLists{
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
}*/