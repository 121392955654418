.navbar{
    display: flex;
    width:100%;
    height:150px;
    
    
}

.navbarLeft{
    display: flex;
    flex:1;
    align-items: center;
    
    
}

.navBarHamburgerMenu{
    
}

.navbarLogo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 20px;
    border-radius: 50%;
}

.navbarRight{
    display: flex;
    
    flex:1;
    align-items: center;
    justify-content: flex-start;
}

.navbarSearchIcon{
    color:#E8E8E8;
    margin-right: 30px;
    font-size: 20px;
    cursor: pointer;
}
.navbarListLeft{
    display:flex;
    
    list-style: none;
    align-self: center;
    
    
    margin-right: 6vw;
}
.navbarListRight{
    display:flex;
    flex:1;
    list-style: none;
    align-self: center;
    
    margin-left: 6vw;
    
}

@media (max-width:600px){
    .navbar{
        height:100px;
        width:310px;
        align-self: center;
    }

    .navbarRight{
        justify-content: flex-end;
    }

    .navbarSearchIcon{
        margin-right: 0;
        cursor: pointer;
        margin-bottom: 7px;
    }

    .navbarLogo {
        width:80px;
        height:80px;
        margin-top: 10px;
    }

    .navbarLogo > img {
        width:80px;
        height:80px;
        border-radius: 50%;
    }

    .navbarHamburger{
        color:#E8E8E8;
    }

    .navBarHamburgerMenu{
        justify-content: flex-start;
        align-items: center;
        margin-left: 0;
        margin-top: 0;
    }

    .mobileSearchBarContainer{
        width:100%;
        position: absolute;
        left:0;
        top:0;
    }
    
}

@media (min-width:601px){ /* TABLET */
    .navbar{
        height:120px;
        width:650px;
        align-self: center;
    }
    .navbarRight{
        justify-content: flex-end;
    }
    .navbarSearchIcon{
        margin-right: 0;
    }
    .navbarLogo {
        width:80px;
        height:80px;
        margin-top: 12px;
    }
    .navbarLogo > img {
        width:80px;
        height:80px;
        border-radius: 50%;
    }
    .navbarHamburger{
        color:#E8E8E8;
    }
    .navBarHamburgerMenu{
        justify-content: flex-start;
        align-items: center;
        margin-left: 0;
    }
    .mobileSearchBarContainer{
        width:100%;
        position: absolute;
        left:0;
        top:0;
    }
}

@media (min-width:601px){
    .navbarLogo {
        width:100px;
        height:100px;
        
    }

    .navbarLogo > img {
        width:100px;
        height:100px;
        border-radius: 50%;
    }
}

@media (min-width:992px){
    .navbarLogo {
        width:100px;
        height:100px;
        
    }

    .navbarLogo > img {
   
        width:100px;
        height:100px;
        border-radius: 50%;
    }

    .navbarLeft{
        justify-content: flex-end;
    }
}

@media (min-width:1001px){ 
    .navbar{
        width:1010px;
        height: 160px;
    }
    .navbarLogo {
        width:160px;
        height:160px;     
        margin-top: 0; 
    }
    .navbarLogo > img {
        width:160px;
        height:160px;
        border-radius: 50%;
    }
    .navbarHamburger{color:#E8E8E8;}
    .navBarHamburgerMenu{align-items: center;}
    .navbarListLeft{
        margin-right: 120px;
    }
    .navbarListRight{
        margin-left: 120px;
    }
    .navbarListItem{
        font-family: 'Oswald';
        font-weight: 700;
        font-size: 25px;
        margin-left: 36px;
    }
    .navbarUserControl{
        display: flex;
        position: absolute;
        left:135px;

    }
    .navbarUserProfileLink{
        width:50px;
        height:50px;
        border-radius: 50%;
    }
    .navbarUserProfileLink > img{
        width:50px;
        height:50px;
        border-radius: 50%;
        border:2px solid #E6C068;
    }
    .navbarUserControl > img {
        width:50px;
        height:50px;
        border-radius: 50%;
        border:2px solid #E6C068;
    }
    .navbarAddReview{
        display: flex;
        justify-content: flex-end;
        flex:1;
        margin-left: 24px;
        
    }
    .navbarAddLink{
        
        width:210px;
        height:50px;
        text-decoration: none;
    }
    
    .navbarNewReviewButton {
        display: flex;
        margin-right: 8vw;
        align-items: center;
        justify-content: center;
        width:210px;
        height:50px;
        font-size: 14px;
        border:none;
        color:white;
        background-color: #E6C068;
        border-radius: 5px;
        cursor: pointer;
        text-decoration: none;
        
    }
    .navbarNewReviewButton > p{
        font-family: 'Oswald';
        font-weight: 500;
        font-size: 22px;
        color:#121212;
    }
    .navbarNewReviewIcon{
        margin-right: 10px;
        font-size: 22px;
        margin-top: 2px;
        width:26px;
    }
}

@media (min-width: 1600px){
    .navbar{
        width:1640px;
        height: 185px;
    }
    .navbarLogo {
        width:180px;
        height:180px;     
        margin-top: 0; 
    }
    .navbarLogo > img {
        width:180px;
        height:180px;
        border-radius: 50%;
    }
    .navbarHamburger{color:#E8E8E8;}
    .navBarHamburgerMenu{align-items: center;}
    .navbarListItem{
        font-family: 'Oswald';
        font-weight: 700;
        font-size: 27px;
        margin-left: 36px;
    }
    .navbarUserControl{
        display: flex;
        margin-right: auto;

    }
    .navbarUserProfileLink{
        width:50px;
        height:50px;
        border-radius: 50%;
    }
    .navbarUserProfileLink > img{
        width:50px;
        height:50px;
        border-radius: 50%;
        border:2px solid #E6C068;
    }
    .navbarUserControl > img {
        width:50px;
        height:50px;
        border-radius: 50%;
        border:2px solid #E6C068;
    }
    .navbarAddReview{
        display: flex;
        justify-content: flex-end;
        flex:1;
        margin-left: 24px;
        
    }
    .navbarAddLink{
        
        width:210px;
        height:50px;
        text-decoration: none;
    }
    
    .navbarNewReviewButton {
        display: flex;
        margin-right: 8vw;
        align-items: center;
        justify-content: center;
        width:210px;
        height:50px;
        font-size: 14px;
        border:none;
        color:white;
        background-color: #E6C068;
        border-radius: 5px;
        cursor: pointer;
        text-decoration: none;
        
    }
    .navbarNewReviewButton > p{
        font-family: 'Oswald';
        font-weight: 500;
        font-size: 22px;
        color:#121212;
    }
    .navbarNewReviewIcon{
        margin-right: 10px;
        font-size: 22px;
        margin-top: 2px;
        width:26px;
    }
}



/*.navbarLogo > img {
   
    width:9vw;
    height:9vw;
    border-radius: 50%;
}*/





.navBarSearchBarContainer{
    display: flex;
    flex:1;
    
    justify-content: flex-end;
    
}



.navbarListItem{
    /*font-size:24px;*/
    /*margin-left:2vw;*/
    cursor: pointer;
    color: white;
}

.navbarListItem:link{
    color:#E8E8E8;
}

.navbarListItem:visited{
    color:#E8E8E8;
}

.navbarListItem:hover{
   
    color: #E6C068;
}





.active{
    text-decoration: underline;
    color: #E6C068;
}

.navbarListItem:first-child{
    margin-left: 0;
}

.searchIcon{
    font-size: 24px;
    
}

.navbarPopup{
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #E6C068;
    width:460px;
    height: 170px;
    
    align-items: center;
    font-weight: bold;
    font-size: 1vw;
    color:#121212;
}

.navbarPopupHeader{
    font-family: 'Oswald';
    font-weight: 600;
    font-size: 32px;
    margin-top: 30px;
}
.navbarPopupSubText{
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 25px;
    margin-top: 15px;
}

@media (min-width:1001px) and (max-width:1599px){
    .navbarPopup{
        display: flex;
        flex-direction: column;
        position: absolute;
        right:0;
        background-color: #E6C068;
        width:450px;
        height: 110px;
        
        align-items: center;
        font-weight: bold;
        font-size: 1vw;
        color:#121212;
    }
    
    .navbarPopupHeader{
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 25px;
        margin-top: 15px;
    }
    .navbarPopupSubText{
        font-family: 'Oswald';
        font-weight: 500;
        font-size: 18px;
        margin-top: 10px;
    }
}