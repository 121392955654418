.singleRecord{
    display: flex;
    flex-direction: column;
    width:100%;
}

.singleReviewContent {
    display: flex;
    color:#E8E8E8;
}

.singleRecordLeft{
    
    margin-left: 7.2vw;
    width:42.8vw;
    
}

.singleRecordRight{
    display: flex;
    justify-content: center;
    width:42.8vw;
}

.singleRecordRight > img{
    margin-top: 18vw;
    width:30vw;
    height:30vw;
    object-fit: cover;
}

.singleReview{
    font-size: 1.2vw;
    margin-top:6vh;
    margin-bottom: 10vh;
    line-height: 2.5vh;
    color:#E8E8E8;
}

.singleTopInfo{
    
    font-weight: 600;
    display: flex;
}

/*.singleTopInfo > p{
    margin-top:1vh;
    margin-left:1vw;
}*/

.singleTopInfo > img {
    width:2.5vw;
    height:2.5vw;
    border-radius: 50%;
}

.singleArtist{
    font-weight: bold;
    font-size:3vw;

}

.singleRecordName{
    font-weight: bold;
    font-size:2vw;
    
}

/*.singleReleaseYear{
    margin-top: 1.5vw;
    font-size:1.2vw;
    color:#E8E8E8;
}

.singlePlayTime{
    font-size:1.2vw;
    color:#E8E8E8;
}*/



.singleRecordEditButton{
    display: flex;
    margin-right: 8vw;
    
    align-items: center;
    justify-content: center;
    width:8vw;
    height:4vh;
    font-size: 14px;
    border:none;
    color:white;
    background-color: black;
    cursor: pointer;   
}

@media (max-width:600px){
    .singleReviewContent{
        flex-direction: column;
        width:310px;
        align-self: center;
        
    }
    .singleReviewBackButton{
        width:30px;
        margin-left: -8px;
        
    }
    .singleTopInfo{
       margin-top: 20px;
    }
    .singleTopInfo > p {
        font-size: 12px;
        font-family: 'Oswald';
        font-weight: 300;
        margin-left: 10px;
        align-self: center;
    }
    .singleTopInfo > img{
        width: 30px;
        height: 30px;
        border:2px solid #E6C068;
    }
    .singleRecordImage{
        width:310px;
        height:310px;
        margin-top: 24px;
        align-self: center;
    }
    .singleRecordImage > img{
        width:310px;
        height:310px;
    }
    .singleArtist{
        margin-top:15px;
        font-size: 22px;
        font-family: 'Oswald';
        font-weight: 700;
    }
    .singleRecordName{
        font-size: 16px;
        font-family: 'Oswald';
        font-weight: 600;
    }

    .singleRecordInfo{
        background-color: #E6C068;
        width:81px;
        height:42px;
        margin-right: 14px;
        margin-top: 10px;
        border-radius: 2px;
        
    }
    .singleRecordInfo > p{
        color:#121212;
        font-size: 12px;
        font-family: 'Oswald';
        font-weight: 300;
        margin-left:5px;
        margin-top:2px;
        
    }
    .singleReview{
        font-size:15px;
        font-family: 'Noto Sans';
        color: #E8E8E8;
        line-height: 22px;
        padding-bottom: 0;
        margin-top:10px;
    }
}

@media (min-width:601px){
    .singleReviewContent{
        flex-direction: column;
        width:650px;
        align-self: center;
        
    }
    .singleReviewBackButton{
        width:40px;
        margin-left: -15px;
        cursor: pointer;
    }
    .singleTopInfo{
       margin-top: 30px;
    }
    .singleTopInfo > p {
        font-size: 18px;
        font-family: 'Oswald';
        font-weight: 300;
        margin-left: 15px;
    }
    .singleTopInfo > img{
        width: 30px;
        height: 30px;
        border:2px solid #E6C068;
    }
    .singleRecordImage{
        width:650px;
        height:650px;
        margin-top: 24px;
        align-self: center;
    }
    .singleRecordImage > img{
        width:650px;
        height:650px;
    }
    .singleArtist{
        margin-top:15px;
        font-size: 32px;
        font-family: 'Oswald';
        font-weight: 700;
    }
    .singleRecordName{
        font-size: 25px;
        font-family: 'Oswald';
        font-weight: 600;
    }

    .singleRecordInfo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        background-color: #E6C068;
        width:95px;
        height:50px;
        margin-right: 14px;
        margin-top: 15px;
        border-radius: 2px;
        
    }
    .singleRecordInfo > p{
        color:#121212;
        font-size: 14px;
        font-family: 'Oswald';
        font-weight: 300;
        margin-left:7px;
        margin-top:2px;
        
    }
    .singleReview{
        font-size:16px;
        font-family: 'Noto Sans';
        font-weight: 500;
        color: #E8E8E8;
        line-height: 24px;
        
        margin-top:35px;
        margin-bottom: 0;
    }
}

@media (min-width:1001px) and (max-width:1599px){
    .singleRecord{
        background-color: #121212;
    }
    .singleReviewContent{
        display: flex;
        flex-direction: column;
        width:1010px;
        height:auto;
        margin-top: 40px;
        background-color: #212121;
        border-radius: 20px;
    }
    .singleReviewContentWrapper{
        width:900px;
        align-self: center;
    }
    .singleReviewBackEdit{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 55px;
    }
    .singleReviewBackButton{
        align-self: flex-start;
        width:40px;
        cursor: pointer;
    }
    .singleRecordEditLink{
        width:210px;
        height:60px;
        display: flex;
        align-self: flex-end;
        background-color: #E6C068;
        color:#121212;
        text-decoration: none;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        margin-right: 40px;
    }
    .singleRecordEditLink > div{
        display: flex;
        align-items: center;
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 20px;
    }
    .singleTopInfo{
        margin-top: 60px;
    }
    .singleTopInfo > img {
        width:45px;
        height:45px;
        border:2px solid #E6C068;
    }
    .singleTopInfo > p{
        margin:0;
        margin-left: 15px;
        align-self: center;
        font-family: 'Oswald';
        font-weight: 300;
        font-size: 18px;

    }
    .singleArtist{
        margin-top: 25px;
        font-family: 'Oswald';
        font-weight: 700;
        font-size: 35px;
        
    }
    .singleRecordName{
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 25px;
    }
    .singleReviewRecordInfo{
        display: flex;
        flex-direction: column;
        width:110px;
        height:56px;
        background-color: #E6C068;
        margin-top: 15px;
        border-radius: 2px;
    }
    .singleReviewRecordInfo > p{
        font-family: 'Oswald';
        font-weight: 300;
        font-size: 15px;
        margin-left: 13px;
        color:#121212
    }
    .singleReleaseYear{
        margin-top: 5px;
    }
    .singlePlayTime{
        
    }
    .singleReviewTextImage{
        display: flex;
        flex-direction: row;
        margin-top: 25px;
        justify-content: space-between;
    }
    .singleReviewText{
        width:610px;
        font-family: 'Noto Sans';
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        color:#E8E8E8;
        padding-bottom: 100px;
    }
    .singleReviewImage{
        
        width: 271px;
        height: 271px;
    }
    .singleReviewImage > img{
        
        width: 271px;
        height: 271px;
        object-fit: cover;
    }
    .singleRecordRemoveButton{
        width:210px;
        height:60px;
        display: flex;
        align-self: flex-end;
        background-color: #E6C068;
        color:#121212;
        text-decoration: none;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 20px;
        cursor: pointer;
    }
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7); /* Adjust opacity as needed */
        z-index: 9998; /* Ensure it appears below the confirmation prompt */
      }
    .confirmationPrompt {
        width:800px;
        height:315px;
        text-align: center;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #2F2F2F;
        border: 5px solid #E6C068;
        padding: 20px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        z-index: 9999; /* Ensure it appears above other content */
      }
      .confirmationPrompt > p{
        margin-top: 50px;
        font-family: 'Noto Sans';
        font-weight: 500;
        font-size: 22px;
      }
      .confirmationPrompt > button{
        width:210px;
        height:60px;
        background-color: #E6C068;
        border:none;
        margin-top: 50px;
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 20px;
        cursor: pointer;
      }
}

@media (min-width:1600px){
    .singleRecord{
        background-color: #121212;
    }
    .singleReviewContent{
        display: flex;
        flex-direction: column;
        width:1640px;
        height:auto;
        
        background-color: #212121;
        border-radius: 20px;
    }
    .singleReviewContentWrapper{
        width:1500px;
        align-self: center;
    }
    .singleReviewBackEdit{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 55px;
    }
    .singleReviewBackButton{
        align-self: flex-start;
        width:40px;
        cursor: pointer;
    }
    .singleRecordEditLink{
        width:210px;
        height:60px;
        display: flex;
        align-self: flex-end;
        background-color: #E6C068;
        color:#121212;
        text-decoration: none;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        margin-right: 40px;
    }
    .singleRecordEditLink > div{
        display: flex;
        align-items: center;
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 20px;
    }
    .singleTopInfo{
        margin-top: 60px;
    }
    .singleTopInfo > img {
        width:50px;
        height:50px;
        border:2px solid #E6C068;
    }
    .singleTopInfo > p{
        margin:0;
        margin-left: 15px;
        align-self: center;
        font-family: 'Oswald';
        font-weight: 300;
        font-size: 22px;

    }
    .singleArtist{
        margin-top: 25px;
        font-family: 'Oswald';
        font-weight: 700;
        font-size: 45px;
        
    }
    .singleRecordName{
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 33px;
    }
    .singleReviewRecordInfo{
        display: flex;
        flex-direction: column;
        width:132px;
        height:62px;
        background-color: #E6C068;
        margin-top: 15px;
        border-radius: 2px;
    }
    .singleReviewRecordInfo > p{
        font-family: 'Oswald';
        font-weight: 300;
        font-size: 18px;
        margin-left: 13px;
        color:#121212
    }
    .singleReleaseYear{
        margin-top: 5px;
    }
    .singlePlayTime{
        margin-bottom: 11px;
    }
    .singleReviewTextImage{
        display: flex;
        flex-direction: row;
        margin-top: 45px;
        justify-content: space-between;
    }
    .singleReviewText{
        width:820px;
        font-family: 'Noto Sans';
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color:#E8E8E8;
        padding-bottom: 100px;
    }
    .singleReviewImage{
        
        width: 500px;
        height: 500px;
    }
    .singleReviewImage > img{
        
        width: 500px;
        height: 500px;
        object-fit: cover;
    }
    .singleRecordRemoveButton{
        width:210px;
        height:60px;
        display: flex;
        align-self: flex-end;
        background-color: #E6C068;
        color:#121212;
        text-decoration: none;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 20px;
        cursor: pointer;
    }
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7); /* Adjust opacity as needed */
        z-index: 9998; /* Ensure it appears below the confirmation prompt */
      }
    .confirmationPrompt {
        width:800px;
        height:315px;
        text-align: center;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #2F2F2F;
        border: 5px solid #E6C068;
        padding: 20px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        z-index: 9999; /* Ensure it appears above other content */
      }
      .confirmationPrompt > p{
        margin-top: 50px;
        font-family: 'Noto Sans';
        font-weight: 500;
        font-size: 22px;
      }
      .confirmationPrompt > button{
        width:210px;
        height:60px;
        background-color: #E6C068;
        border:none;
        margin-top: 50px;
        font-family: 'Oswald';
        font-weight: 600;
        font-size: 20px;
        cursor: pointer;
      }
}