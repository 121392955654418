.mobileWelcome{
    background-color: #121212;
    height:500px;
    
}

.mobileWelcomeTitle{
    /*position: relative;*/
    top:20px;
    margin-left: auto;
    margin-right: auto;
    width:100%;
    font-family: 'Oswald';
    font-size:27px;
    font-weight: 600;
    color:#E8E8E8;  
    max-width: 13ch;
}

.mobileWelcomeTitle > p{
    color:#E6C068;
}

.mobileWelcome > hr {
    margin-top: 40px;
    width:80px;
    margin-left:auto;
    margin-right:auto;
    color:#E6C068;
    
}

.mobileWelcomeText{
    font-size:16px;
    color:#E8E8E8;
    
    overflow-wrap: break-word;
    
    font-family: 'Oswald';
    font-weight: bold;
    line-height: 1.3;
    
}

.mobileWelcomeText > p {
    margin-top: 10px;
    max-width: 296px;
    margin-left: auto;
    margin-right:auto;
    text-align: center;
}

@media (max-width:600px){
    .mobileWelcomeTitle{
        
        padding-top: 40px;
    }
    .mobileWelcomeText{
        font-family: 'Noto Sans';
        font-weight: 500;
        font-size:15px;
        line-height: 22px;
    }
    .mobileWelcomeHorizontalLine{
        width:125px;
        border-top: 2px solid #E6C068;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
    }
}

@media (min-width:601px){
    .mobileWelcome{
        height:420px;
    }
    
    .mobileWelcomeTitle{
        max-width: 600px;
        text-align: center;
        font-size: 32px;
        padding-top: 50px;
    }
    .mobileWelcome > hr{
        width:175px;
    }
    .mobileWelcomeText{
        font-family: 'Noto Sans';
        font-weight: 500;
        font-size:16px;
        line-height: 24px;
       
    }
    .mobileWelcomeText > p {
        margin-top: 10px;
        max-width: 580px;
        margin-left: auto;
        margin-right:auto;
        text-align: center;
    }
    .mobileWelcomeHorizontalLine{
        width:175px;
        border-top: 2px solid #E6C068;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
    }
}

@media (min-width:1001px){
    .mobileWelcome{
        height:450px;
    }
    .mobileWelcomeTitle{
        max-width: 600px;
        text-align: center;
        font-size: 32px;
        padding-top: 55px;
    }
    .mobileWelcomeText{
        font-size:18px;
        line-height: 26px;
       
    }
    .mobileWelcomeHorizontalLine{
        width:175px;
        border-top: 2px solid #E6C068;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
    }
}

@media (min-width:1600px){
    .mobileWelcome{
        height:550px;
    }
    .mobileWelcomeTitle{
        max-width: 600px;
        text-align: center;
        font-size: 45px;
        padding-top: 55px;
    }
    .mobileWelcomeText{
        font-size:20px;
        line-height: 30px;
       
    }
    .mobileWelcomeHorizontalLine{
        width:275px;
        border-top: 2px solid #E6C068;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
    }
}