.editAbout{
    display: flex;
    flex-direction: column;
    width:100%;
    background-color: #121212;
    align-items: center;
    
}

.editAboutWrapper{
    display:flex;
    flex-direction: column;
    
    width:100%;
    background-color: #212121;
    width:1640px;
    justify-content: center;
}
.editAboutHeader{
    display: flex;
    flex-direction: row;
    width:810px;
    margin-left: 73px;
    margin-top: 40px;
    justify-content: space-between;
    
}
.editAboutHeader > p{
    display: flex;
    justify-content: center;
    justify-self: flex-end;
    text-align: center;
    font-family: 'Oswald';
    font-weight: 700;
    font-size: 45px;
    color:#E6C068;
}
.editAboutHeader > img{
    cursor: pointer;
    width:40px;
}


.editAboutContent{
    display: flex;
    margin-top:70px;
    background-color: #212121;
    width:1490px;
    align-self: center;
    justify-content: space-between;
}

.editAboutDesc{
    
    width:850px;
    
    align-self: flex-start;
}

.editAboutRight{
    display: flex;
    
    
    width:590px;
    height:430px;
    
    
    align-items: center;
    justify-content: space-between;
    
}

.editImageWrapper{
    display: flex;
    flex-direction: column;
    width:280px;
    height:430px;
    align-items: center;
    background-color: #2F2F2F;
    border-radius: 10px;
}

.editImageLeft{
    display:flex;
    width:200px;
    height:200px;
    justify-content: center;
    border:2px solid black;
    border-radius: 50%;
    margin-top: 45px;
}
.editImageRight{
    display:flex;
    width:200px;
    height:200px;
    justify-content: center;
    border:2px solid black;
    border-radius: 50%;
    margin-top: 45px;
}

.addImageIcon{
    font-size:42px;
    cursor: pointer;
}

.addIconText{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width:100%;
    height:100%;
    
}

.addCloseImageIcon{
    position:absolute;
    top:0;
    right:2px;
    z-index: 1;
    font-size: 32px;
    cursor: pointer;
    
}

.addImagePreview{
    position: relative;
    width:100%;
    height:100%;
    
    
}

.editImage{
    width:100%;
    height:100%;
    object-fit: cover;
    z-index: 1;
    border-radius: 50%;
    border:5px solid #E6C068;
    
}

.editImageHover{
    width:100%;
    height:100%;
    object-fit: cover;
    z-index: 2;
    border-radius: 50%;
    opacity: 0.5;
    border:5px solid #E6C068;
}

.editImageIcon{
    position:absolute;
    align-self: center;
    font-size: 2vw;
    color:white;
    cursor: pointer;
    z-index: 4;
}

.editAboutFavoritesSection{
    display: flex;
    flex-direction: column;
    background-color: #212121;
    width:100%;
    
    align-items: center;
    margin-top: 140px;
    
}

.editAboutFavoritesText{
    color:#E6C068;
    font-family: 'Oswald';
    font-size: 45px;
    font-weight: 700;
    margin-top:95px;
}

.editAboutSearchBar{
    margin-top: 30px;
}

.editAboutSearchText{
    color:#E6C068;
    font-family: 'Oswald';
    font-size: 32px;
    font-weight: 500;
    margin-top:105px;
}

@media (min-width:1001px) and (max-width:1599px){
    .editAboutWrapper{
        width:1010px;
        border-radius: 20px;
    }
    .editAboutHeader > p{
        margin-right: 310px;
    }
    .editAboutContent{
        width:650px;
        flex-direction: column;
        align-items: center;
    }
    .editAboutDesc{
        width:650px;
    }
    .editAboutRight{
        width:493px;
    }
    .editImageWrapper{
        width:236px;
        height:320px;
        background-color: #121212;
    }
    .editImageLeft{
        width:150px;
        height:150px;
    }
    .editImageRight{
        width:150px;
        height:150px;
    }
}